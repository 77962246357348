import { useEffect } from 'react';
import '@inovua/reactdatagrid-enterprise/index.css';
import {
  faArrowsRotate,
  faBook,
  faChartSimple,
  faChevronLeft,
  faChevronRight,
  faEllipsisVertical,
  faFileExcel,
  faGauge,
  faPenToSquare,
  faPlus,
  faReceipt,
  faTableList,
  faTooth,
  faTrash,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './store/hooks';
import RoutesAdmin from './RoutesAdmin';
import { authActions } from './store/auth/auth.reducers';
import { getAuthState, isPlatformUser } from './services/firebase.services';
import { User } from 'firebase/auth';
import { isAuthenticatedSelector } from './store/auth/auth.selectors';
import { history } from './models/history.tsx';
import { saveUserStateForTests } from './utils/utils.tsx';

library.add(
  faFileExcel,
  faEllipsisVertical,
  faTableList,
  faChartSimple,
  faTooth,
  faReceipt,
  faGauge,
  faUsers,
  faPlus,
  faChevronRight,
  faChevronLeft,
  faPenToSquare,
  faBook,
  faTrash,
  faCopy,
  faArrowsRotate
);

const App = () => {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (import.meta.env.VITE_NODE_ENV === 'test') {
      saveUserStateForTests(dispatch);
    } else {
      const saveUserState = async (user: User | null) => {
        dispatch(
          authActions.saveUserState({
            firebaseUser: JSON.stringify(user),
            isAuthorized: !!user && (await isPlatformUser())
          })
        );
      };
      getAuthState(saveUserState);
    }
  }, []);

  return (
    <>
      <div id="portal-root"></div>
      <div id="side-bar-modal-root"></div>
      <div id="dropdown-root"></div>
      <div id="dropdown-menu-root"></div>
      <div id="tooltip-root"></div>
      <div id="dialog-root"></div>
      {isAuthenticated !== undefined && <RoutesAdmin></RoutesAdmin>}
    </>
  );
};
export default App;
