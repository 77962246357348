import { useEffect, useState } from 'react';
import { BreadcrumbItem, BreadcrumbsBar, Wizard } from '@platform-storybook/circlestorybook';
import { useAppDispatch } from '../../../../store/hooks';
import i18next from 'i18next';
import { usersActions } from '../../../../store/users/users.reducers';
import EstablishmentForm from './user-forms/establishment-form/EstablishmentForm';
import IdentificationForm from './user-forms/identification-form/IdentificationForm';
import { Establishment, UserInfo } from '../../../../models/user';
import styles from './create-user-page.module.scss';
import CustomizationForm from './user-forms/customization-form/CustomizationForm';
import { useNavigate } from 'react-router-dom';

import { AllRoles, DentalNotation, Language } from '../../../../enum/user';
import {
  fetchClinicsAsync,
  fetchDesignCentersAsync,
  fetchLaboratoriesAsync
} from '../../../../store/establishment/establishment.reducer';
import { getMessageError } from '../../../../utils/utils';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../enum/feedback';
import { useCreateUserMutation } from '../../../../services/users-rtkq.services.tsx';

const CreateUserPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createUser, { isSuccess: isCreateUserSuccess, error: createUserError }] =
    useCreateUserMutation();
  const [selectedStep, setSelectedStep] = useState(0);
  const steps = [
    { id: 0, title: i18next.t('userForm.identificationGroup', { ns: 'user' }) },
    { id: 1, title: i18next.t('userForm.customizationGroup', { ns: 'user' }) },
    {
      id: 2,
      title: i18next.t('establishmentModal.establishmentGroup', { ns: 'user' })
    }
  ];

  const getEmptyUser = () =>
    new (class implements UserInfo {
      role: AllRoles | undefined;
      clinic: Establishment | undefined;
      designCenter: Establishment | undefined;
      email: string | undefined;
      firstName: string | undefined;
      laboratory: Establishment | undefined;
      language: Language | undefined;
      lastConnection: string | undefined;
      lastName: string | undefined;
      phoneNumber: string | undefined;
      policyValidationDate: string | undefined;
      dentalNotation: DentalNotation | undefined;
      id: number | undefined;
      activationDate: string | undefined;
    })();

  useEffect(() => {
    dispatch(usersActions.setUser(getEmptyUser()));
    dispatch(fetchClinicsAsync())
      .unwrap()
      .catch((error) =>
        dispatch(
          feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
        )
      );
    dispatch(fetchLaboratoriesAsync())
      .unwrap()
      .catch((error) =>
        dispatch(
          feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
        )
      );
    dispatch(fetchDesignCentersAsync())
      .unwrap()
      .catch((error) =>
        dispatch(
          feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
        )
      );
  }, []);

  const handlePrevious = () => {
    setSelectedStep(selectedStep - 1);
  };

  const handleSubmit = (userParam: UserInfo) => {
    createUser(userParam);
  };

  useEffect(() => {
    if (isCreateUserSuccess) {
      dispatch(usersActions.setUser(getEmptyUser()));
      dispatch(
        feedbackActions.setToast({
          message: i18next.t('userForm.userCreated', { ns: 'user' }),
          type: ToastType.SUCCESS
        })
      );
      navigate('/users');
    } else if (createUserError) {
      // If we have a dedicated error message, use it
      // If not, let the default error message be displayed
      dispatch(
        feedbackActions.setToast({
          message: getMessageError(createUserError),
          type: ToastType.DANGER
        })
      );
    }
  }, [isCreateUserSuccess, createUserError]);

  const navigateNextStep = () => {
    setSelectedStep(selectedStep + 1);
  };

  return (
    <div className={styles['create-user-form']}>
      <BreadcrumbsBar type={'navigation'}>
        <BreadcrumbItem
          text={i18next.t('tabs.users', { ns: 'user' })}
          link="/users"></BreadcrumbItem>
        <BreadcrumbItem
          text={i18next.t('userForm.titleCreate', { ns: 'user' })}
          link="users/create"></BreadcrumbItem>
      </BreadcrumbsBar>
      <div className={styles['create-user-form__main']}>
        <Wizard
          options={steps}
          selectedIndex={selectedStep}
          displayedInRow={false}
          className={styles['create-user-form__wizard']}
        />
        {selectedStep === 0 && <IdentificationForm nextCallback={navigateNextStep} />}
        {selectedStep === 1 && (
          <CustomizationForm nextCallback={navigateNextStep} previousCallback={handlePrevious} />
        )}
        {selectedStep === 2 && (
          <EstablishmentForm submitCallback={handleSubmit} previousCallback={handlePrevious} />
        )}
      </div>
    </div>
  );
};

export default CreateUserPage;
