import { useTranslation } from 'react-i18next';
import useForm from '../../../../../../utils/useForm';
import { Box, Button, TextField, Dropdown, BubblePicto } from '@platform-storybook/circlestorybook';
import { Product, ProductUpdateForm } from '../../../../../../models/product';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import styles from './edit-product-forms.module.scss';
import { useGetCommonTypesQuery } from '../../../../../../services/common-types-rtkq.services';
import { usePatchProductMutation } from '../../../../../../services/products-rtkq.services';
import { StringObject, UnknownObject } from '../../../../../../models/common';
import {
  familyDropdownSelector,
  productCategoriesDropdownSelector,
  productClassesDropdownSelector,
  productSubCategoriesDropdownSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { useAppSelector } from '../../../../../../store/hooks';

interface SubcategoriesDropdown {
  label: string;
  value: string;
  isChecked: boolean;
}

type Props = {
  productToEdit: Product;
  onSubmitCallback?: () => void;
};

const IdentificationForm = ({ productToEdit, onSubmitCallback }: Props) => {
  const { t } = useTranslation(['catalog']);
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const [updateProduct, { isLoading: isPatchLoading }] = usePatchProductMutation();
  const familiesDropdown = useAppSelector(familyDropdownSelector);
  const categoriesDropdown = useAppSelector(productCategoriesDropdownSelector);
  let subCategoriesDropdown = useAppSelector(productSubCategoriesDropdownSelector);
  subCategoriesDropdown = subCategoriesDropdown.map((subCategoryDropdown) => {
    return {
      ...subCategoryDropdown,
      isChecked:
        productToEdit?.subCategories?.includes(subCategoryDropdown.value as string) ?? false
    };
  });
  const classesDropdown = useAppSelector(productClassesDropdownSelector);

  const onSubmit = async () => {
    const subcategories = ((values?.subCategories as SubcategoriesDropdown[][])?.[0] || [])
      .filter((subCategory) => subCategory.isChecked)
      .map((subCategory) => subCategory.value);
    await updateProduct({
      ...values,
      class: values.family === commonTypes?.families?.REMOVABLE ? values.class : null,
      id: productToEdit.id.toString(),
      subCategories: subcategories
    } as ProductUpdateForm);

    if (onSubmitCallback) onSubmitCallback();
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSelect,
    handleMultiSelect,
    handleSubmit
  } = useForm(
    {
      labelFr: productToEdit?.labelFr || '',
      labelEn: productToEdit?.labelEn || '',
      family: productToEdit?.family || '',
      class: productToEdit?.class || undefined,
      category: productToEdit?.category || undefined,
      subCategories: [subCategoriesDropdown],
      imageUrl: productToEdit?.imageUrl || ''
    },
    onSubmit,
    (values: UnknownObject) => {
      // Empty class if family is no longer Removable
      if (values?.family !== commonTypes?.families?.REMOVABLE && values?.class) {
        values.class = undefined;
      }
      const newErrors: StringObject = {};
      ['labelFr', 'labelEn', 'family', 'imageUrl'].forEach((key) => {
        if (!values[key]) {
          newErrors[key] = 'empty';
        }
      });

      if (values?.family === commonTypes?.families?.REMOVABLE && !values.class) {
        newErrors['class'] = 'empty';
      }

      return newErrors;
    }
  );

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit}>
        <TextField
          id="labelFr"
          name="labelFr"
          value={values.labelFr}
          label={t('products.productForm.labelFr.label')}
          placeholder="Couronne provisoire"
          helperText={
            errors.labelFr
              ? t('products.productForm.labelFr.error')
              : t('products.productForm.labelFr.helper')
          }
          className={styles['edit-product-forms__input']}
          onBlur={handleBlur}
          onChange={handleChange}
          variant={errors.labelFr ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          data-cy="identification__label-fr"></TextField>
        <TextField
          id="labelEn"
          name="labelEn"
          value={values.labelEn}
          label={t('products.productForm.labelEn.label')}
          placeholder="Provisional crown"
          helperText={
            errors.labelEn
              ? t('products.productForm.labelEn.error')
              : t('products.productForm.labelEn.helper')
          }
          className={styles['edit-product-forms__input']}
          onBlur={handleBlur}
          onChange={handleChange}
          variant={errors.labelEn ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          data-cy="identification__label-en"></TextField>
        <Dropdown
          value={values.family}
          label={t('products.productForm.family.label')}
          data={areCommonTypesLoading ? [] : [familiesDropdown]}
          isLoading={areCommonTypesLoading}
          placeholder={t('products.productForm.dropdownPlaceholder')}
          onChange={(newValue: string) => handleSelect(newValue, 'family')}
          className={styles['edit-product-forms__dropdown']}
          variant={errors.family ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          helperText={errors.family && t('products.productForm.family.error')}
          data-cy="identification__family"></Dropdown>
        <Dropdown
          value={values.category}
          onChange={(newValue: string) => handleSelect(newValue, 'category')}
          label={t('products.productForm.category.label')}
          data={areCommonTypesLoading ? [] : [categoriesDropdown]}
          isLoading={areCommonTypesLoading}
          placeholder={t('products.productForm.dropdownPlaceholder')}
          className={styles['edit-product-forms__dropdown']}
          helperText={t('products.productForm.category.helper')}
          data-cy="identification__category"></Dropdown>
        <Dropdown
          multichoice={true}
          label={t('products.productForm.subCategories.label')}
          name="subCategories"
          onChange={(newValue: string) => handleMultiSelect(newValue, 'subCategories')}
          data={values.subCategories}
          isLoading={areCommonTypesLoading}
          placeholder={t('products.productForm.dropdownPlaceholder')}
          className={styles['edit-product-forms__dropdown']}
          helperText={t('products.productForm.subCategories.helper')}
          data-cy="identification__sub-categories"></Dropdown>
        {!areCommonTypesLoading && values.family === commonTypes?.families?.REMOVABLE && (
          <Dropdown
            value={values.class}
            onChange={(newValue: string) => handleSelect(newValue, 'class')}
            label={t('products.productForm.class.label')}
            data={areCommonTypesLoading ? [] : [classesDropdown]}
            isLoading={areCommonTypesLoading}
            placeholder={t('products.productForm.dropdownPlaceholder')}
            variant={errors.class ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['edit-product-forms__dropdown']}
            helperText={errors.class && t('products.productForm.class.error')}
            data-cy="identification__class"></Dropdown>
        )}
        <div className={styles['edit-product-forms__input__icon__container']}>
          <TextField
            id="imageUrl"
            name="imageUrl"
            value={values.imageUrl}
            label={t('products.productForm.icon.label')}
            placeholder={t('products.productForm.icon.placeholder')}
            helperText={
              errors.imageUrl
                ? t('products.productForm.icon.error')
                : t('products.productForm.icon.helper')
            }
            className={styles['edit-product-forms__input__icon']}
            onBlur={handleBlur}
            onChange={handleChange}
            variant={errors.imageUrl ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            data-cy="identification__image-url"></TextField>
          {values.family && (
            <BubblePicto
              size="large"
              displaysBorder={true}
              data-cy="bubble-picto-preview"
              color={
                ColorPropsEnum[
                  `FAMILY_${(values.family as string).toUpperCase()}` as keyof typeof ColorPropsEnum
                ]
              }
              url={values.imageUrl}
            />
          )}
          {!values.family && (
            <BubblePicto size="large" data-cy="bubble-picto-preview" url={values.imageUrl} />
          )}
        </div>
        <div className={styles['edit-product-forms__form-button']}>
          <Button
            label={t('action.update', { ns: 'common' })}
            type="submit"
            isLoading={isPatchLoading}
            data-cy="identification__submit"
          />
        </div>
      </form>
    </Box>
  );
};

export default IdentificationForm;
