import { setClaims } from '../../services/security-api.services.tsx';
import { HttpStatusCode } from 'axios';
import { forceRefreshToken, signInWithUserCustomToken } from '../../services/firebase.services.tsx';
import { UserCredential } from 'firebase/auth';
import { ErrorFeedBack } from '../../models/feedback.tsx';
import { ErrorCode, ErrorUIType } from '../../enum/feedback.ts';
import i18next from '../../i18n.tsx';
import { GetThunkAPI } from '@reduxjs/toolkit';

// @ts-expect-error Thunk
const failureCallback = (err: { code: ErrorCode }, thunkAPI: GetThunkAPI) => {
  let error: ErrorFeedBack = {
    feedbackType: ErrorUIType.ATTENTION_BOX,
    message: Object.values(ErrorCode).includes(err.code)
      ? i18next.t(err.code, { ns: 'error' })
      : i18next.t('error.failed', { ns: 'auth' })
  };

  switch (err.code) {
    case ErrorCode.auth_wrong_password:
    case ErrorCode.auth_user_not_found:
    case ErrorCode.auth_too_many_requests:
      error.feedbackType = ErrorUIType.ATTENTION_BOX;
      break;
    case ErrorCode.auth_invalid_email:
    case ErrorCode.auth_missing_password:
      error.feedbackType = ErrorUIType.FIELD;
      break;
    default:
      error = {
        feedbackType: ErrorUIType.ATTENTION_BOX,
        message: i18next.t('error.failed', { ns: 'auth' })
      };
  }
  return thunkAPI.rejectWithValue(error);
};

const genericFeedbackError = (thunkAPI: GetThunkAPI<never>) => {
  // @ts-expect-error Thunk type is not accessible.
  return thunkAPI.rejectWithValue({
    feedbackType: ErrorUIType.ATTENTION_BOX,
    message: i18next.t('error.failed', { ns: 'auth' })
  });
};

const getCustomClaims = async (email: string, userCredential: UserCredential | void) => {
  if (userCredential) {
    // Get the id token to update the claims with MS-Users.
    const idToken = await (userCredential as UserCredential).user.getIdToken();
    // Update the claims with the access control data needed.
    const customToken = await setClaims(email, idToken);
    if (customToken.status === HttpStatusCode.Ok) {
      await signInWithUserCustomToken(customToken.data);
      // Force the token previously created to get the new claims.
      await forceRefreshToken();

      return Promise.resolve({
        isAuthenticated: true
      });
    }
  }
  return Promise.reject({
    isAuthenticated: false
  });
};

export { failureCallback, getCustomClaims, genericFeedbackError };
