import styles from '../create-component-page.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Chips, Dialog, Text } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { componentSelector } from '../../../../../../store/components/components.selectors';
import {
  CheckboxImplantAttachment,
  CheckboxItem,
  CheckboxStratificationTechnique,
  DropdownItem
} from '../../../../../../models/form';
import { createComponent } from '../../../../../../services/components.services';
import { feedbackActions } from '../../../../../../store/feedback/feedback.reducer';
import { capitalize, getMessageError } from '../../../../../../utils/utils';
import { getValue } from '../../components';
import { getLocalizedProperty } from '../../../catalog';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { ToastType } from '../../../../../../enum/feedback';
import {
  ImplantAttachmentEnum,
  ToothStratificationTechniqueEnum
} from '../../../../../../enum/component';
import { NewComponent } from '../../../../../../models/component.tsx';
import { useGetCommonTypesQuery } from '../../../../../../services/common-types-rtkq.services.ts';

type Props = {
  submitCallback: () => void;
  previousCallback: () => void;
};

const SummaryForm = ({ submitCallback, previousCallback }: Props) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const component = useAppSelector(componentSelector);
  const { data: commonTypes } = useGetCommonTypesQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const handleEscKey = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsDialogOpened(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [handleEscKey]);

  const handleClick = () => {
    setIsDialogOpened(true);
  };

  const addNewComponent = async () => {
    const newComponent = {
      ...component,
      materials: mapCheckboxToBody(component?.materials),
      structures: mapCheckboxToBody(component?.structures),
      shades: mapDropdownToBody(component?.shades),
      brands: mapDropdownToBody(component?.brands),
      aspects: mapCheckboxToBody(component?.aspects),
      shapes: mapCheckboxToBody(component?.shapes),
      angulations: mapCheckboxToBody(component?.angulations),
      implantAttachments: mapCheckboxToImplantAndStratificationEnumBody(
        component?.implantAttachments
      ),
      toothStratificationTechniques: mapCheckboxToImplantAndStratificationEnumBody(
        component?.toothStratificationTechniques
      )
    };

    try {
      setIsLoading(true);
      await createComponent(newComponent as NewComponent);
      submitCallback();
    } catch (e) {
      console.error(e);
      dispatch(feedbackActions.setToast({ message: getMessageError(e), type: ToastType.DANGER }));
    } finally {
      setIsLoading(false);
      setIsDialogOpened(false);
    }
  };

  const mapCheckboxToBody = (
    dataCheckbox: Array<CheckboxItem> | undefined
  ): Array<{ id: number }> | undefined => {
    return dataCheckbox?.filter((data) => data.isChecked).map((data) => ({ id: data.value }));
  };

  const mapDropdownToBody = (
    dataDropdown: Array<Array<DropdownItem>> | undefined
  ): Array<{ id: number }> | undefined => {
    return dataDropdown
      ?.map((section) => section.filter((item) => item.isChecked))
      .flat(1)
      .map((item) => ({ id: item.value }));
  };

  const mapCheckboxToImplantAndStratificationEnumBody = (
    checkboxData: Array<CheckboxImplantAttachment | CheckboxStratificationTechnique> | undefined
  ): Array<ImplantAttachmentEnum | ToothStratificationTechniqueEnum> | null | undefined => {
    if (checkboxData && checkboxData.length > 0) {
      const checkedData = checkboxData.filter((item) => item.isChecked).map((item) => item.value);
      if (checkedData.length > 0) {
        return checkedData;
      }
      return null;
    }

    // If there is no data, we make sure to return undefined
    // Since some properties are not allowed to be defined according to their component type in the micro-service
    return undefined;
  };

  const mapMaterialToDoubleLabelChip = (material: CheckboxItem) => {
    const labels: { firstLabel: string; secondLabel?: string } = { firstLabel: material.label };
    const materialData = commonTypes?.materials?.find(
      (thisMaterial) => thisMaterial.id === material.value
    );

    if (!materialData) {
      return labels;
    }

    if (materialData.stratification) {
      labels.firstLabel = capitalize(t(`materials.${materialData.code}`));
      labels.secondLabel = capitalize(t(`materials.stratification.${materialData.stratification}`));
    }

    return labels;
  };

  const flattenedBrands = component?.brands?.flat();
  const flattenedShades = component?.shades?.flat();
  return (
    <>
      <Dialog
        title={t('components.componentForm.create.dialog.title', {
          label: getValue(component, getLocalizedProperty('label'))
        })}
        isOpened={isDialogOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('components.componentForm.createComponent')}
        onCancel={() => setIsDialogOpened(false)}
        onConfirm={addNewComponent}
        isLoading={isLoading}
      />
      <form className={styles['create-component-page__form']}>
        <Box className={styles['create-component-page__box--scrollable']}>
          <>
            <div className={styles['create-component-page__summary__text']}>
              <Text
                label={t('components.componentForm.labelFrInput.label')}
                color={ColorPropsEnum.GREY}
              />
              <Text label={component?.labelFr} />
            </div>
            <div className={styles['create-component-page__summary__text']}>
              <Text
                label={t('components.componentForm.labelEnInput.label')}
                color={ColorPropsEnum.GREY}
              />
              <Text label={component?.labelEn} />
            </div>
            {component?.svgLayer && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.svgLayerInput.label')}
                  color={ColorPropsEnum.GREY}
                />
                <Text label={component.svgLayer} />
              </div>
            )}
            <div className={styles['create-component-page__summary__text']}>
              <Text
                label={t('components.componentForm.componentTypeInput.label')}
                color={ColorPropsEnum.GREY}
              />
              <Text label={t(`componentTypes.${component?.componentType}`)} />
            </div>
            {component?.manufacturingProcess && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.manufacturingProcess.detailLabel')}
                  color={ColorPropsEnum.GREY}
                />
                <Text label={t(`manufacturingProcesses.${component.manufacturingProcess}`)} />
              </div>
            )}
            {component?.materials && component.materials.length > 0 && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.materialInput.label')}
                  color={ColorPropsEnum.GREY}
                />
                {component?.materials
                  .filter((data) => data.isChecked)
                  .map((element) => {
                    const labels: { firstLabel: string; secondLabel?: string } =
                      mapMaterialToDoubleLabelChip(element);
                    return (
                      <Chips
                        key={element.value}
                        color={ColorPropsEnum.FAMILY_GUARDS}
                        className={styles['create-component-page__summary__chips']}
                        firstLabel={labels.firstLabel}
                        secondLabel={labels.secondLabel}
                      />
                    );
                  })}
                {component?.materials.filter((data) => data.isChecked).length === 0 && (
                  <Text label={t('components.componentForm.noSelection')} />
                )}
              </div>
            )}
            {component?.shades && component.shades.length > 0 && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.shadeInput.label')}
                  color={ColorPropsEnum.GREY}
                />
                {flattenedShades
                  ?.filter((item) => item.isChecked)
                  .map((element) => (
                    <Chips
                      key={element.value}
                      color={
                        ColorPropsEnum[element.label.toUpperCase() as keyof typeof ColorPropsEnum]
                      }
                      className={styles['create-component-page__summary__chips']}
                      firstLabel={element.label}
                    />
                  ))}
                {flattenedShades?.filter((data) => data.isChecked).length === 0 && (
                  <Text label={t('components.componentForm.noSelection')} />
                )}
              </div>
            )}
            {component?.aspects && component.aspects.length > 0 && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.aspectInput.label')}
                  color={ColorPropsEnum.GREY}
                />
                {component?.aspects
                  .filter((data) => data.isChecked)
                  .map((element) => (
                    <Chips
                      key={element.value}
                      color={ColorPropsEnum.FAMILY_GUARDS}
                      className={styles['create-component-page__summary__chips']}
                      firstLabel={element.label}
                    />
                  ))}
                {component?.aspects.filter((data) => data.isChecked).length === 0 && (
                  <Text label={t('components.componentForm.noSelection')} />
                )}
              </div>
            )}
            {component?.shapes && component?.shapes.length > 0 && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.shapeInput.label')}
                  color={ColorPropsEnum.GREY}
                />
                {component?.shapes
                  .filter((data) => data.isChecked)
                  .map((element) => (
                    <Chips
                      key={element.value}
                      color={ColorPropsEnum.FAMILY_GUARDS}
                      className={styles['create-component-page__summary__chips']}
                      firstLabel={element.label}
                    />
                  ))}
                {component?.shapes.filter((data) => data.isChecked).length === 0 && (
                  <Text label={t('components.componentForm.noSelection')} />
                )}
              </div>
            )}
            {component?.structures && component?.structures.length > 0 && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.structureInput.label')}
                  color={ColorPropsEnum.GREY}
                />
                {component?.structures
                  .filter((data) => data.isChecked)
                  .map((element) => (
                    <Chips
                      key={element.value}
                      color={ColorPropsEnum.FAMILY_GUARDS}
                      className={styles['create-component-page__summary__chips']}
                      firstLabel={element.label}
                    />
                  ))}
                {component?.structures.filter((data) => data.isChecked).length === 0 && (
                  <Text label={t('components.componentForm.noSelection')} />
                )}
              </div>
            )}
            {component?.implantAttachments && component?.implantAttachments.length > 0 && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.implantAttachmentsInput.label')}
                  color={ColorPropsEnum.GREY}
                />
                {component?.implantAttachments
                  .filter((data) => data.isChecked)
                  .map((element) => (
                    <Chips
                      key={element.value}
                      color={ColorPropsEnum.FAMILY_GUARDS}
                      className={styles['create-component-page__summary__chips']}
                      firstLabel={element.label}
                    />
                  ))}
                {component?.implantAttachments.filter((data) => data.isChecked).length === 0 && (
                  <Text label={t('components.componentForm.noSelection')} />
                )}
              </div>
            )}
            {component?.brands && component?.brands.length > 0 && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.brandInput.label')}
                  color={ColorPropsEnum.GREY}
                />
                {flattenedBrands
                  ?.filter((item) => item.isChecked)
                  .map((element) => (
                    <Chips
                      key={element.value}
                      color={ColorPropsEnum.FAMILY_GUARDS}
                      className={styles['create-component-page__summary__chips']}
                      firstLabel={element.label}
                    />
                  ))}
                {flattenedBrands?.filter((item) => item.isChecked).length === 0 && (
                  <Text label={t('components.componentForm.noSelection')} />
                )}
              </div>
            )}
            {component?.angulations && component?.angulations.length > 0 && (
              <div className={styles['create-component-page__summary__text']}>
                <Text
                  label={t('components.componentForm.angulationInput.label')}
                  color={ColorPropsEnum.GREY}
                />
                {component?.angulations
                  .filter((data) => data.isChecked)
                  .map((element) => (
                    <Chips
                      key={element.value}
                      color={ColorPropsEnum.FAMILY_GUARDS}
                      className={styles['create-component-page__summary__chips']}
                      firstLabel={element.label}
                    />
                  ))}
                {component?.angulations.filter((data) => data.isChecked).length === 0 && (
                  <Text label={t('components.componentForm.noSelection')} />
                )}
              </div>
            )}
            {component?.toothStratificationTechniques &&
              component?.toothStratificationTechniques.length > 0 && (
                <div className={styles['create-component-page__summary__text']}>
                  <Text
                    label={t('components.componentForm.toothStratificationTechniquesInput.label')}
                    color={ColorPropsEnum.GREY}
                  />
                  {component?.toothStratificationTechniques
                    .filter((data) => data.isChecked)
                    .map((element) => (
                      <Chips
                        key={element.value}
                        color={ColorPropsEnum.FAMILY_GUARDS}
                        className={styles['create-component-page__summary__chips']}
                        firstLabel={element.label}
                      />
                    ))}
                  {component?.toothStratificationTechniques.filter((data) => data.isChecked)
                    .length === 0 && <Text label={t('components.componentForm.noSelection')} />}
                </div>
              )}
          </>
        </Box>
        <div className="form__buttons">
          <Button
            label={t('action.previous', { ns: 'common' })}
            category="secondary"
            onClick={previousCallback}
            iconLeft="fa-chevron-left"
          />
          <Button label={t('components.componentForm.createComponent')} onClick={handleClick} />
        </div>
      </form>
    </>
  );
};

export default SummaryForm;
