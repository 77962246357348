// To avoid duplicating an enum with all the steps and an enum with the modelling steps,
// we've created several enums, including the modelling enum, which we merge and export all together.
// The reason we're creating three enums instead of two is to maintain the order of the steps

enum WorkFlowStepFirstPartEnum {
  SUBMITTING = 'submitting', // dentist is submitting the order
  VALIDATION = 'validation' // validate order by lab
}

export enum WorkflowModelingStepEnum {
  MODELING = 'modeling', // check products compatibility to CircleCAD
  MODELING_ANALYZE = 'modeling_analyze', // analyze patients files with CircleCAD (automatic)
  MODELING_PREPARE = 'modeling_prepare', // prepare patients files with CircleCAD (manual)
  MODELING_REGISTER = 'modeling_register', // register patients files with CircleCAD (automatic)
  MODELING_DESIGN = 'modeling_design', // design patients files with CircleCAD (manual)
  MODELING_EXPORT = 'modeling_export' // export manufacturing files with CircleCAD (automatic)
}

enum WorkFlowStepLastPartEnum {
  MANUFACTURING = 'manufacturing', // manufacturing products and components
  ASSEMBLY = 'assembly', // Assemble products and components
  CONTROL = 'control', // control products and order
  DELIVERY = 'delivery', // order waiting to be shipped to dentist
  SHIPPED = 'shipped', // order has been shipped to dentist
  DELIVERED = 'delivered' // order has been delivered
}
export const WorkflowStepEnum = {
  ...WorkFlowStepFirstPartEnum,
  ...WorkflowModelingStepEnum,
  ...WorkFlowStepLastPartEnum
};

export type WorkflowStepEnum =
  | WorkFlowStepFirstPartEnum
  | WorkflowModelingStepEnum
  | WorkFlowStepLastPartEnum;

export enum WorkFlowPendingStepPreModelingEnum {
  PENDING_SUBMITTING = 'pending_submitting', // intermediate status before workflow set it to SUBMITTING
  PENDING_VALIDATION = 'pending_validation' // intermediate status before workflow set it to VALIDATION
}

export enum WorkflowModelingPendingStepEnum {
  PENDING_MODELING = 'pending_modeling', // intermediate status before workflow set it to MODELING
  PENDING_MODELING_ANALYZE = 'pending_modeling_analyze' // intermediate status before workflow set it to MODELING_ANALYZE
}

export enum WorkflowPostModelingPendingStepEnum {
  PENDING_CONTROL = 'pending_control', // intermediate status before workflow set it to CONTROL
  PENDING_DELIVERY = 'pending_delivery', // intermediate status before workflow set it to DELIVERY
  PENDING_SHIPPED = 'pending_shipped', // intermediate status before workflow set it to SHIPPED
  PENDING_DELIVERED = 'pending_delivered' // intermediate status before workflow set it to DELIVERED
}

// All pending steps that the workflow is supposed to patch. These steps should not last longer than X seconds.
export const WorkflowPendingStepEnum = {
  ...WorkFlowPendingStepPreModelingEnum,
  ...WorkflowModelingPendingStepEnum,
  ...WorkflowPostModelingPendingStepEnum
};
Object.freeze(WorkflowPendingStepEnum);

export type WorkflowPendingStepEnum =
  | WorkFlowPendingStepPreModelingEnum
  | WorkflowModelingPendingStepEnum
  | WorkflowPostModelingPendingStepEnum;
