import { FilterProps, TypeColWithNamePropertyPlatform } from '../../../../../../models/datagrid';
import i18next from 'i18next';
import {
  Chips,
  Checkbox,
  Tooltip,
  TooltipContent,
  Text
} from '@platform-storybook/circlestorybook';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import stylesDataGrid from '../../../../../../features/datagrid/datagrid-feature.module.scss';
import styles from '../create-product-page.module.scss';
import { CommonTypes } from '../../../../../../models/common-types';
import { getLocalizedProperty } from '../../../catalog';
import {
  Product,
  ProductCreationForm,
  ProductWithCompatibility
} from '../../../../../../models/product';
import { CheckboxColumnHeader } from '../../../../../../utils/CheckboxColumnHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { getColumnOptions } from '../../../../../../features/datagrid/datagrid.utils.ts';

export interface RenderArg {
  value?: string;
  data?: object;
}

export type DatagridCheckbox = {
  column: string;
  onCheckboxClick?: (productId: number, column: string, value: boolean) => void;
  onHeaderCheckboxClick?: (headerKey: string) => void;
  headerChecked?: boolean;
  width: number;
};

export const getValue = (data: object, valueName: string): string => {
  return data
    ? Object.entries(data)
        .filter((v) => v.length > 0 && v[0] === valueName)
        .map((v) => v[1].toString())[0]
    : '';
};

export const colProductLabel: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions('label', i18next.t('datagrid.columns.label', { ns: 'compatibilities' }), 2),
  showColumnMenuTool: false,
  cellDOMProps: () => ({
    style: {
      textAlign: 'left',
      textTransform: 'capitalize'
    }
  }),
  render: ({ data }: RenderArg) => {
    if (data) {
      const labelLocal = getValue(data, getLocalizedProperty('label'));
      return labelLocal.toLowerCase();
    }
  }
};

export const colFamily = (commonTypes: CommonTypes): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'family',
      i18next.t('datagrid.columns.family', { ns: 'compatibilities' }),
      2
    ),
    showColumnMenuTool: false,
    textEllipsis: false,
    maxWidth: 120,
    cellDOMProps: () => ({
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '4px',
        alignItems: 'center'
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource:
        commonTypes.families &&
        Object.values(commonTypes.families).map((family) => {
          return {
            id: family,
            label: i18next.t(`products.families.${family}`, {
              ns: 'catalog'
            })
          };
        })
    },
    render: ({ value }) => {
      if (value) {
        return (
          <Chips
            className={stylesDataGrid['datagrid-feature__chips']}
            key={value.toLowerCase()}
            firstLabel={i18next.t(`products.families.${value}`, {
              ns: 'catalog'
            })}
            color={ColorPropsEnum[`FAMILY_${value.toUpperCase()}` as keyof typeof ColorPropsEnum]}
          />
        );
      }
    }
  };
};

export const colCheckBox = (checkbox: DatagridCheckbox): TypeColWithNamePropertyPlatform => {
  return {
    name: checkbox.column,
    header: () => {
      return (
        <>
          <div>{i18next.t('datagrid.columns.' + checkbox.column, { ns: 'compatibilities' })}</div>
          <Tooltip maxWidthPx={400}>
            <span
              className={
                styles['create-product-page__compatibility-form__datagrid__header__helper']
              }>
              <FontAwesomeIcon icon={faQuestion} size="xs" />
            </span>
            <TooltipContent>
              <Text
                color={ColorPropsEnum.WHITE}
                label={i18next.t('datagrid.tooltip.' + checkbox.column, {
                  ns: 'compatibilities'
                })}></Text>
            </TooltipContent>
          </Tooltip>
        </>
      );
    },
    showColumnMenuTool: false,
    showColumnMenuToolOnHover: false,
    enableColumnFilterContextMenu: false,
    width: checkbox.width,
    filterEditor:
      checkbox.onHeaderCheckboxClick !== undefined && checkbox.headerChecked !== undefined
        ? CheckboxColumnHeader
        : undefined,
    filterEditorProps:
      checkbox.onHeaderCheckboxClick !== undefined && checkbox.headerChecked !== undefined
        ? {
            onHeaderCheckboxClick: checkbox.onHeaderCheckboxClick,
            checked: checkbox.headerChecked,
            headerKey: checkbox.column
          }
        : undefined,
    render: ({ data }) => {
      if (typeof data[checkbox.column] === 'boolean') {
        return (
          <Checkbox
            className={stylesDataGrid['datagrid-feature__checkbox']}
            isChecked={data[checkbox.column]}
            isDisabled={checkbox.onCheckboxClick === undefined}
            onClick={() => {
              if (checkbox?.onCheckboxClick)
                checkbox.onCheckboxClick(data['id'], checkbox.column, !data[checkbox.column]);
            }}
          />
        );
      }
    }
  };
};

export const getFilters = (filterValue: Array<FilterProps> | undefined) => {
  let filters = '';
  filterValue
    ?.filter((filter) => filter.value)
    ?.forEach((filter) => {
      filters = `${filters}&filter.${filter.name}=$ilike:${filter.value}`;
      if (filter.name === 'label') {
        filters = `${filters}&filter.${getLocalizedProperty(filter.name)}=$ilike:${filter.value}`;
      }
    });
  return filters;
};

export const hasCompatibilities = (product: ProductCreationForm) => {
  return product?.productCompatibilities && product.productCompatibilities.length > 0;
};

export const computeDatagridDatasource = (
  currentProduct: ProductCreationForm,
  activeProducts: Product[]
) => {
  return activeProducts.map((activeProduct) => {
    let toothCompatibility = false;
    let archCompatibility = false;
    let orderCompatibility = false;
    if (currentProduct.productCompatibilities) {
      currentProduct.productCompatibilities.forEach((productCompatibility) => {
        if (productCompatibility.compatibleProduct.id === activeProduct.id) {
          orderCompatibility = true;
          toothCompatibility = productCompatibility.toothCompatibility;
          archCompatibility = productCompatibility.archCompatibility;
        }
      });
    }
    return {
      ...activeProduct,
      orderCompatibility: orderCompatibility,
      archCompatibility: archCompatibility,
      toothCompatibility: toothCompatibility
    };
  });
};

export const computeDetailDatagridDatasource = (
  currentProduct: Product | undefined,
  activeProducts: Product[] | undefined
) => {
  const compatibleProducts: ProductWithCompatibility[] = [];

  if (activeProducts && currentProduct) {
    activeProducts.forEach((activeProduct) => {
      if (currentProduct.productCompatibilities) {
        currentProduct.productCompatibilities.forEach((productCompatibility) => {
          if (
            activeProduct.id !== currentProduct.id &&
            (productCompatibility.compatibleProduct.id === activeProduct.id ||
              productCompatibility.product.id === activeProduct.id)
          ) {
            compatibleProducts.push({
              ...activeProduct,
              orderCompatibility: true,
              archCompatibility: productCompatibility.archCompatibility,
              toothCompatibility: productCompatibility.toothCompatibility
            });
          }
        });
      }
    });
  }

  return compatibleProducts;
};
