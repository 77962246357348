import styles from '../create-component-page.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Fieldset,
  Button,
  CheckboxList,
  Dropdown,
  Text
} from '@platform-storybook/circlestorybook';
import useForm from '../../../../../../utils/useForm';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  allowedMaterialsCheckboxSelector,
  allowedAspectsCheckboxSelector,
  allowedShadesDropdownSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { componentsActions } from '../../../../../../store/components/components.reducers';
import { componentSelector } from '../../../../../../store/components/components.selectors';
import { CheckboxStringItem } from '../../../../../../models/form';
import { ComponentCreationForm } from '../../../../../../models/component.tsx';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const MaterialShadeForm = ({ nextCallback, previousCallback }: Props) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();

  const allowedShadesDropdown = useAppSelector(allowedShadesDropdownSelector);
  const allowedAspectsCheckbox = useAppSelector(allowedAspectsCheckboxSelector);
  const allowedMaterialsCheckbox = useAppSelector(allowedMaterialsCheckboxSelector);
  const component = useAppSelector(componentSelector);

  const saveComponentInfo = () => {
    dispatch(componentsActions.setComponent({ ...component, ...values } as ComponentCreationForm));
  };

  const onSubmit = () => {
    saveComponentInfo();
    nextCallback();
  };

  const handlePreviousButton = () => {
    saveComponentInfo();
    previousCallback();
  };

  const { handleSubmit, handleCheck, handleMultiSelect, values } = useForm(
    {
      materials: component?.materials ?? allowedMaterialsCheckbox,
      shades: component?.shades ?? allowedShadesDropdown,
      aspects: component?.aspects ?? allowedAspectsCheckbox
    },
    onSubmit
  );

  const noChoiceAvailable =
    allowedMaterialsCheckbox.length === 0 &&
    allowedShadesDropdown?.length === 0 &&
    allowedAspectsCheckbox?.length === 0;

  return (
    <form onSubmit={handleSubmit} className={styles['create-component-page__form']}>
      <Fieldset size="m" className={styles['create-component-page__form__fieldset']}>
        {noChoiceAvailable ? (
          <Text
            data-cy="component-material-shade-no-choice"
            label={t('components.componentForm.noChoice')}></Text>
        ) : (
          <>
            {allowedMaterialsCheckbox.length > 0 && (
              <CheckboxList
                data-cy="component-materials"
                name="materials"
                title={t('components.componentForm.materialInput.label')}
                data={values.materials as CheckboxStringItem[]}
                onClick={handleCheck}
                className={styles['create-component-page__form__fieldset__input']}
              />
            )}
            {allowedShadesDropdown?.length > 0 && (
              <Dropdown
                data-cy="component-shades"
                multichoice={true}
                label={t('components.componentForm.shadeInput.label')}
                name="shades"
                placeholder={t('components.componentForm.choose')}
                data={values.shades}
                onChange={(selectedValue: number) => handleMultiSelect(selectedValue, 'shades')}
                className={styles['create-component-page__form__fieldset__input']}
              />
            )}
            {allowedAspectsCheckbox?.length > 0 && (
              <CheckboxList
                data-cy="component-aspects"
                name="aspects"
                title={t('components.componentForm.aspectInput.label')}
                data={values?.aspects}
                onClick={handleCheck}
                className={styles['create-component-page__form__fieldset__input']}
              />
            )}
          </>
        )}
      </Fieldset>
      <div className="form__buttons">
        <Button
          label={t('action.previous', { ns: 'common' })}
          category="secondary"
          onClick={handlePreviousButton}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default MaterialShadeForm;
