export enum ComponentType {
  FRAME = 'FRAME',
  TOOTH = 'TOOTH',
  GINGIVA = 'GINGIVA',
  PROSTHETIC_STUMP = 'PROSTHETIC_STUMP',
  IMPLANT_ATTACHMENT = 'IMPLANT_ATTACHMENT',
  GUARD = 'GUARD',
  WAX_ON_HARD_BASE = 'WAX_ON_HARD_BASE',
  IMPLANT_SYSTEM = 'IMPLANT_SYSTEM',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  PARTIAL_TOOTH = 'PARTIAL_TOOTH',
  MODELS = 'MODELS',
  PINK_GINGIVA = 'PINK_GINGIVA'
}

export enum MaterialEnum {
  COMPOSITE = 'COMPOSITE',
  RESIN = 'RESIN',
  ZIRCONIUM = 'ZIRCONIUM',
  LITHIUM_DISILICATE = 'LITHIUM_DISILICATE',
  TITANIUM = 'TITANIUM',
  CHROME_COBALT = 'CHROME_COBALT',
  GOLD = 'GOLD',
  LAYERED_CERAM = 'LAYERED_CERAM',
  FIBER_COMPOSITE = 'FIBER_COMPOSITE',
  PEEK = 'PEEK',
  ACETAL = 'ACETAL',
  ACRYLIC_RESIN = 'ACRYLIC_RESIN',
  BI_MATERIAL_ACETAL = 'BI_MATERIAL_ACETAL'
}
