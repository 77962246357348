import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { useParams } from 'react-router-dom';
import {
  BreadcrumbItem,
  BreadcrumbsBar,
  BubblePicto,
  Button,
  Chips,
  Divider,
  Skeleton,
  Text
} from '@platform-storybook/circlestorybook';

import styles from './product-details-page.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate, getCircleSoftwareCompatibility } from '../../../../../utils/utils';
import { getLocalizedProperty } from '../../catalog';
import {
  ComponentInProductCreationForm,
  ComponentInProductUpdateForm,
  Product,
  ProductWithCompatibility
} from '../../../../../models/product';
import i18next from 'i18next';
import { getComponentListItemSupportingText } from '../product';
import ListFeature, { ListFeatureProps } from '../../../../../features/list/ListFeature';
import { ComponentInProduct } from '../../../../../models/component';
import DatagridFeature from '../../../../../features/datagrid/DatagridFeature';
import {
  colCheckBox,
  colFamily,
  colProductLabel,
  computeDetailDatagridDatasource
} from '../create-product-page/create-product-forms/compatibilities';
import { InjectionModeEnum } from '../../../../../enum/product.enum';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { getColumnOptions } from '../../../../../features/datagrid/datagrid.utils.ts';
import { ItemTypeBgColorEnum } from '../../../../../features/list/list-item/list-item.utils.ts';
import {
  useGetActiveProductsQuery,
  useGetOneProductQuery,
  usePatchProductMutation
} from '../../../../../services/products-rtkq.services.tsx';
import IdentificationForm from './edit/IdentificationForm.tsx';
import AdminSidebarModal, {
  ActionBtn
} from '../../../../../features/admin-sidebar-modal/AdminSidebarModal.tsx';
import { useGetCommonTypesQuery } from '../../../../../services/common-types-rtkq.services.ts';
import AddComponentForm from '../create-product-page/create-product-forms/add-component-form/AddComponentForm.tsx';
import ConfigurationForm from './edit/ConfigurationForm.tsx';
import ProductRuleForm from './edit/ProductRuleForm.tsx';
import { useGetCircleCadVersionsQuery } from '../../../../../services/circle-cad-version.services.ts';
import { useGetActiveComponentsQuery } from '../../../../../services/components-rtkq.services.tsx';
import { isConnectedUserAdminSelector } from '../../../../../store/users/users.selectors.tsx';

export interface ModalOptions {
  form: React.JSX.Element;
  onClose?: () => void;
  title: string;
}

const ProductDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation(['catalog']);

  const editComponentRef = useRef<HTMLButtonElement>(null);

  const [activeComponent, setActiveComponent] = useState<
    ComponentInProductCreationForm | undefined
  >(undefined);
  const [compatibleProducts, setCompatibleProducts] = useState<ProductWithCompatibility[]>([]);

  const isConnectedUserAdmin = useAppSelector(isConnectedUserAdminSelector);

  const { data: commonTypes } = useGetCommonTypesQuery();
  useGetCircleCadVersionsQuery();
  useGetActiveComponentsQuery({});
  const { data: productDetails, isLoading: isProductLoading } = useGetOneProductQuery(id, {
    skip: !id
  });
  const { data: activeProducts, isLoading: areActiveProductsLoading } = useGetActiveProductsQuery(
    {},
    { skip: !id }
  );
  const [patchProduct] = usePatchProductMutation();

  const circleSoftwareCompatibility = getCircleSoftwareCompatibility(productDetails);

  useMemo(() => {
    if (productDetails && activeProducts) {
      setCompatibleProducts(
        computeDetailDatagridDatasource(productDetails, activeProducts.data as Product[])
      );
    }
  }, [productDetails, activeProducts]);

  useEffect(() => {
    if (activeComponent) {
      editComponentRef?.current?.click();
    }
  }, [activeComponent]);

  const buildHeader = (
    title: string | undefined,
    buttons: ActionBtn[],
    withIcon = false,
    ref?: RefObject<HTMLButtonElement>
  ) => {
    const disableButton = buttons.find((button) => button.label === 'disable');
    // other buttons use the AdminSidebarModal (Button  + modal comopnent)
    const otherButtons = buttons.filter((button) => button.label !== 'disable');

    return (
      <header className={styles['product-details-page__content__section__header']}>
        {productDetails && withIcon && (
          <BubblePicto
            className={styles['product-details-page__content__section__bubblePicto']}
            size="small"
            displaysBorder={true}
            color={
              ColorPropsEnum[
                `FAMILY_${productDetails.family.toUpperCase()}` as keyof typeof ColorPropsEnum
              ]
            }
            url={productDetails.imageUrl}
            data-cy="bubble-picto-product-details"
          />
        )}
        {title ? (
          <Text
            className={
              styles[
                withIcon
                  ? 'product-details-page__content__section__title--with-icon'
                  : 'product-details-page__content__section__title--without-icon'
              ]
            }
            label={title}
            size="s"
            type="title"></Text>
        ) : (
          <Skeleton type="title" />
        )}
        <div className={styles['product-details-page__content__section__actions']}>
          {isProductLoading && <Skeleton type="title" />}
          {disableButton && productDetails && !productDetails.deletedAt && isConnectedUserAdmin && (
            <Button
              className={styles['product-details-page__content__section__actions__deactivation']}
              label={t('action.deactivate', { ns: 'common' })}
              category="secondary"
              isDisabled={disableButton.isDisabled}
              size={'s'}></Button>
          )}
          {otherButtons?.map(
            (button, index) =>
              productDetails &&
              isConnectedUserAdmin && (
                <AdminSidebarModal
                  key={`${index}_modal`}
                  title={button.modal?.title as string}
                  actionBtn={
                    {
                      label: button.label,
                      dataCy: button.dataCy,
                      icon: button.icon,
                      isDisabled: button.isDisabled
                    } as ActionBtn
                  }
                  {...(button.modal?.onClose && {
                    onCloseCallback: button.modal.onClose
                  })}
                  ref={ref}>
                  {button.modal?.form || <div></div>}
                </AdminSidebarModal>
              )
          )}
        </div>
      </header>
    );
  };

  const buildTextItem = (title: string, value: string | undefined) => {
    return (
      <div className={styles['product-details-page__content__section__content__item']}>
        <Text label={title} color={ColorPropsEnum.GREY}></Text>
        {isProductLoading && <Skeleton type="text" />}
        {productDetails && <Text label={value}></Text>}
      </div>
    );
  };

  const buildComponentList = () => {
    const listFeatureProps: ListFeatureProps = {
      items: [],
      className: styles['product-details-page__content__section__list']
    };
    productDetails?.components?.forEach((component) => {
      const omitComponent = (id: number, array: ComponentInProduct[]) => {
        const index = productDetails?.components.findIndex((component) => component.id === id);
        // If the id is not found, return the array unchanged
        if (index === -1) {
          return array;
        }
        // Use slice to exclude the item at the found index
        return [...array.slice(0, index), ...array.slice(index + 1)];
      };

      listFeatureProps.items.push({
        itemKey: component.id?.toString() as string,
        headlineHref: `/catalog/components/${component.id}/detail`,
        headline: component[getLocalizedProperty('name') as keyof ComponentInProduct] as string,
        supportingText: getComponentListItemSupportingText(component),
        actions: [
          isConnectedUserAdmin && {
            faIconClass: 'pen-to-square',
            dataCy: 'edit-components',
            onClick: () =>
              setActiveComponent({
                id: component.id,
                componentType: component.componentType,
                label: component.labelFr,
                rule: component.rule
              } as ComponentInProductCreationForm)
          },
          isConnectedUserAdmin && {
            faIconClass: 'trash',
            onClick: () =>
              patchProduct({
                id: productDetails.id.toString(),
                components: (
                  omitComponent(
                    component.id as number,
                    productDetails?.components
                  ) as ComponentInProductUpdateForm[]
                ).map((component) => {
                  return {
                    id: component.id,
                    rule: component.rule
                  };
                })
              }),
            color: ColorPropsEnum.DANGER
          }
        ].filter(Boolean) as Array<{
          faIconClass: string;
          color?: string;
          onClick: (id?: number) => void;
          className?: string;
          helper?: string;
          dataCy?: string;
        }>,
        bgColor: ItemTypeBgColorEnum.GREY
      });
    });

    return <ListFeature {...listFeatureProps} />;
  };

  const computeDatagridHeight = (dataLength?: number): { minHeight: number } | undefined => {
    if (dataLength) {
      const headerHeight = 41;
      const rowHeight = 40;
      // We add half a row to make it obvious it's scrollable
      const maxDatagridHeight = headerHeight + rowHeight * 10.5;
      let computedHeight = headerHeight + dataLength * rowHeight;
      computedHeight = computedHeight < maxDatagridHeight ? computedHeight : maxDatagridHeight;
      return computedHeight ? { minHeight: computedHeight } : undefined;
    }
    return { minHeight: 150 };
  };
  return (
    <div>
      <BreadcrumbsBar>
        <BreadcrumbItem
          text={t('products.productForm.breadcrumb.products')}
          link="/catalog/products"></BreadcrumbItem>
        <BreadcrumbItem
          text={t('products.productForm.breadcrumb.details')}
          link={`/catalog/products/${id}/detail`}></BreadcrumbItem>
      </BreadcrumbsBar>
      <div className={styles['product-details-page__content']}>
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(
            productDetails
              ? (productDetails[getLocalizedProperty('name') as keyof Product] as string)
              : undefined,
            [
              {
                label: t('action.edit', { ns: 'common' }),
                icon: 'fa-pen-to-square',
                dataCy: 'edit-identification',
                modal: {
                  title: t('products.editProduct.identification', { ns: 'catalog' }),
                  form: <IdentificationForm productToEdit={productDetails as Product} />
                }
              },
              {
                label: 'disable',
                icon: '',
                dataCy: 'disable-product',
                isDisabled: true
              }
            ],
            true
          )}
          <div className={styles['product-details-page__content__section__content']}>
            <div className={styles['product-details-page__content__section__content__item']}>
              <Text
                label={t('products.productForm.status.label')}
                color={ColorPropsEnum.GREY}></Text>
              {isProductLoading && <Skeleton type="text" />}
              {productDetails && (
                <Chips
                  firstLabel={i18next.t(productDetails.deletedAt ? 'inactive' : 'active', {
                    ns: 'common'
                  })}
                  secondLabel={
                    productDetails.deletedAt ? formatDate(new Date(productDetails.deletedAt)) : ''
                  }
                  color={productDetails.deletedAt ? ColorPropsEnum.DANGER : ColorPropsEnum.SUCCESS}
                  size="m"></Chips>
              )}
            </div>
            {buildTextItem(t('products.productForm.labelFr.label'), productDetails?.labelFr)}
            {buildTextItem(t('products.productForm.labelEn.label'), productDetails?.labelEn)}
            <div className={styles['product-details-page__content__section__content__item']}>
              <Text
                label={t('products.productForm.family.label')}
                color={ColorPropsEnum.GREY}></Text>
              {isProductLoading && <Skeleton type="text" />}
              {productDetails && (
                <Chips
                  firstLabel={t(`products.families.${productDetails.family}`)}
                  secondLabel={
                    productDetails.class ? t(`products.classes.${productDetails.class}`) : undefined
                  }
                  color={
                    productDetails.family
                      ? ColorPropsEnum[
                          `FAMILY_${productDetails.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                        ]
                      : ColorPropsEnum.PRIMARY
                  }
                  size="m"></Chips>
              )}
            </div>
            {productDetails?.category &&
              buildTextItem(
                t('products.productForm.category.summaryLabel'),
                t(`products.categories.${productDetails.category}`)
              )}
            {productDetails?.subCategories && productDetails.subCategories.length > 0 && (
              <div
                className={styles['product-details-page__content__section__content__item']}
                data-cy="product_details__sub-categories">
                <Text
                  label={t('products.productForm.subCategories.summaryLabel')}
                  color={ColorPropsEnum.GREY}></Text>
                {isProductLoading && <Skeleton type="text" />}
                {productDetails?.subCategories?.map((subCategory) => (
                  <Text key={subCategory} label={t(`products.subCategories.${subCategory}`)}></Text>
                ))}
              </div>
            )}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(
            t('products.productForm.wizardSteps.components'),
            [
              {
                label: t(`action.add`, { ns: 'common' }),
                icon: 'fa-solid fa-plus',
                modal: {
                  title: activeComponent
                    ? t('products.editProduct.editComponent', { ns: 'catalog' })
                    : t('products.editProduct.addComponent', { ns: 'catalog' }),
                  form: (
                    <AddComponentForm
                      productToEdit={productDetails as Product}
                      component={activeComponent}
                      resetComponentTypeToEdit={() => setActiveComponent(undefined)}
                    />
                  ),
                  onClose: () => setActiveComponent(undefined)
                },
                dataCy: 'add-components'
              }
            ],
            false,
            editComponentRef
          )}
          <div className={styles['product-details-page__content__section__content']}>
            {productDetails && buildComponentList()}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(t('products.productForm.wizardSteps.conf'), [
            {
              label: t('action.edit', { ns: 'common' }),
              icon: 'fa-pen-to-square',
              modal: {
                title: t('products.editProduct.editConfiguration', { ns: 'catalog' }),
                form: <ConfigurationForm productToEdit={productDetails as Product} />
              },
              dataCy: 'edit-configuration'
            }
          ])}
          <div className={styles['product-details-page__content__section__content']}>
            {buildTextItem(
              t('products.productForm.circleCadVersion.detailLabel'),
              productDetails?.circleCadVersion && productDetails?.circleCadVersion !== ''
                ? productDetails?.circleCadVersion
                : t('products.productForm.circleCadVersion.noData')
            )}
            {productDetails?.circleCadProductType &&
              buildTextItem(
                t('products.productForm.circleCadProductType.label'),
                productDetails.circleCadProductType
              )}
            {buildTextItem(
              t('products.productForm.circleSoftwareCompatibility.label'),
              t(
                `products.productForm.circleSoftwareCompatibility.${circleSoftwareCompatibility.toLowerCase()}`
              )
            )}
            {productDetails &&
              buildTextItem(
                t('products.productForm.teethMode.label'),
                t(`teethModes.${productDetails.teethMode}`)
              )}
            {productDetails &&
              buildTextItem(
                t('products.productForm.stumpMode.label'),
                t(`stumpModes.${productDetails.stumpMode}`)
              )}
            {productDetails &&
              productDetails.injectionMode === InjectionModeEnum.SELECTABLE &&
              buildTextItem(
                t('products.productForm.injectionMode.label'),
                t('yes', { ns: 'common' })
              )}
            {productDetails &&
              buildTextItem(
                t('products.productForm.proportionalBilling.title'),
                productDetails.proportionalBilling
                  ? t('products.productForm.proportionalBilling.label')
                  : t('products.productForm.proportionalBilling.productBilling')
              )}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(t('products.productForm.wizardSteps.rules'), [
            {
              label: t('action.edit', { ns: 'common' }),
              icon: 'fa-pen-to-square',
              modal: {
                title: t('products.editProduct.productRules', { ns: 'catalog' }),
                form: <ProductRuleForm productToEdit={productDetails as Product} />
              },
              dataCy: 'edit-rules'
            }
          ])}
          <div className={styles['product-details-page__content__section__content']}>
            {!productDetails?.productRule?.allowSameProductInOrder &&
              productDetails?.productRule?.allowSaneTeethInArch &&
              !productDetails?.productRule?.allowSameProductOnArch &&
              buildTextItem('', t('products.productForm.summary.noProductRules'))}
            {productDetails?.productRule?.allowSameProductInOrder &&
              buildTextItem('', t('products.productForm.allowSameProductInOrder.label'))}
            {productDetails?.productRule?.allowSameProductOnArch &&
              buildTextItem('', t('products.productForm.allowSameProductOnArch.label'))}
            {!productDetails?.productRule?.allowSaneTeethInArch &&
              buildTextItem('', t('products.productForm.removeSaneTeethInArch.label'))}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(t('products.productForm.wizardSteps.compatibility'), [
            {
              label: t('action.edit', { ns: 'common' }),
              icon: 'fa-pen-to-square',
              modal: {
                title: t('products.editProduct.identification', { ns: 'catalog' }),
                form: <IdentificationForm productToEdit={productDetails as Product} />
              },
              dataCy: 'edit-compatibilities',
              isDisabled: true
            }
          ])}
          <div className={styles['product-details-page__content__section__content']}>
            {!areActiveProductsLoading && commonTypes && (
              <DatagridFeature
                style={computeDatagridHeight(compatibleProducts.length)}
                columns={[
                  colProductLabel,
                  colFamily(commonTypes),
                  colCheckBox({
                    column: 'toothCompatibility',
                    width: 80
                  }),
                  colCheckBox({
                    column: 'archCompatibility',
                    width: 80
                  }),
                  colCheckBox({
                    column: 'orderCompatibility',
                    width: 110
                  })
                ]}
                dataSource={compatibleProducts}
                livePagination={false}
                pagination={false}
              />
            )}
          </div>
        </section>
        <Divider />
        <section className={styles['product-details-page__content__section']}>
          {buildHeader(t('products.productForm.laboratories.label'), [
            {
              label: t('action.edit', { ns: 'common' }),
              icon: 'fa-pen-to-square',
              modal: {
                title: t('products.editProduct.identification', { ns: 'catalog' }),
                form: <IdentificationForm productToEdit={productDetails as Product} />
              },
              dataCy: 'edit-activated-laboratories',
              isDisabled: true
            }
          ])}
          <div className={styles['product-details-page__content__section__content']}>
            <DatagridFeature
              style={computeDatagridHeight((productDetails?.labProducts || []).length)}
              columns={[
                {
                  ...getColumnOptions(
                    'labName',
                    t('products.productForm.labsDatagrid.labName'),
                    1,
                    false,
                    false
                  )
                },
                {
                  ...getColumnOptions(
                    'productionDelay',
                    t('products.productForm.labsDatagrid.productDelay'),
                    1,
                    false,
                    false
                  )
                },
                {
                  ...getColumnOptions(
                    'unitPrice',
                    t('products.productForm.labsDatagrid.unitPrice'),
                    1,
                    false,
                    false
                  )
                }
              ]}
              dataSource={productDetails?.labProducts || []}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProductDetailsPage;
