import { Dialog } from '@platform-storybook/circlestorybook';
import { useAppDispatch } from '../../../../store/hooks';
import { useDeleteOrderMutation } from '../../../../services/orders-rtkq.services';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { useDeleteManufacturingOrderMutation } from '../../../../services/manufacturing-orders-rtkq.services';
import i18next from 'i18next';
import { ErrorCode, ToastType } from '../../../../enum/feedback';
import { useState } from 'react';

type props = {
  title: string;
  orderNumber?: string;
  onCancel: () => void;
  onConfirmSuccess?: () => void;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  isOpened: boolean;
};

const DeleteOrderDialog = ({
  title,
  orderNumber,
  cancelButtonLabel,
  confirmButtonLabel,
  isOpened,
  onCancel,
  onConfirmSuccess
}: props) => {
  const dispatch = useAppDispatch();
  const [deleteManufacturingOrder] = useDeleteManufacturingOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const onConfirmDelete = async () => {
    if (orderNumber) {
      setIsLoadingSubmit(true);
      try {
        await deleteOrder({ orderNumber: orderNumber }).unwrap();
        await deleteManufacturingOrder({ orderNumber: orderNumber });

        if (onConfirmSuccess) onConfirmSuccess();
        dispatch(
          feedbackActions.setToast({
            message: i18next.t('toast.orderDeleted', {
              orderNumber: orderNumber,
              ns: 'orders'
            }),
            type: ToastType.SUCCESS
          })
        );
      } catch (error) {
        // if manufacturing order was not found, we ignore the error.
        // otherwise it will be caught by the global error catcher
        if (
          error instanceof Error &&
          'data' in error &&
          (error as { data?: { error?: string } }).data?.error === ErrorCode.MANU_ORDER_003
        ) {
          if (onConfirmSuccess) onConfirmSuccess();
          dispatch(
            feedbackActions.setToast({
              message: i18next.t('toast.orderDeleted', {
                orderNumber: orderNumber,
                ns: 'orders'
              }),
              type: ToastType.SUCCESS
            })
          );
        }
      } finally {
        setIsLoadingSubmit(false);
      }
    }
  };

  const onCancelDelete = () => {
    if (onCancel) onCancel();
  };

  return (
    <Dialog
      title={title}
      isOpened={isOpened}
      cancelButtonLabel={cancelButtonLabel}
      confirmButtonLabel={confirmButtonLabel}
      onCancel={() => onCancelDelete()}
      onConfirm={() => onConfirmDelete()}
      isLoading={isLoadingSubmit}
      data-cy={'delete-order-dialog'}
    />
  );
};

export default DeleteOrderDialog;
