export enum InjectionModeEnum {
  NONE = 'NONE',
  SELECTABLE = 'SELECTABLE'
}

export enum CircleSoftwareCompatibilityEnum {
  NONE = 'NONE',
  CHAIRSIDE = 'CHAIRSIDE',
  ONEDESIGN = 'ONEDESIGN',
  CIRCLECAD = 'CIRCLECAD'
}

export enum CircleCadProductTypeEnum {
  removable = 'removable',
  COURONNE_ESTHE_MONO = 'COURONNE_ESTHE_MONO',
  COURONNE_METAL = 'COURONNE_METAL',
  COURONNE_CERAM_STRATIFIEE = 'COURONNE_CERAM_STRATIFIEE',
  ENDOCOURONNE = 'ENDOCOURONNE',
  INLAY = 'INLAY',
  ONLAY = 'ONLAY',
  VENEER = 'VENEER',
  VENEER_PROVISOIRE = 'VENEER_PROVISOIRE',
  OVERLAY = 'OVERLAY',
  BRIDGE_MONO_METAL = 'BRIDGE_MONO_METAL',
  BRIDGE_ESTHE_MONO = 'BRIDGE_ESTHE_MONO',
  BRIDGE_CERAM_STRATIFIE = 'BRIDGE_CERAM_STRATIFIE',
  COURONNE_PROVISOIRE = 'COURONNE_PROVISOIRE',
  BRIDGE_PROVISOIRE = 'BRIDGE_PROVISOIRE',
  INLAYCORE = 'INLAYCORE'
}
