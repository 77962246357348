import React, { useState } from 'react';
import styles from './login-page.module.scss';
import {
  AttentionBox,
  Button,
  Chips,
  Divider,
  Link,
  Text,
  TextField,
  Toast
} from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authActions } from '../../../store/auth/auth.reducers';
import { useTranslation } from 'react-i18next';
import {
  attentionBoxErrorSelector,
  filedErrorSelector,
  toastSelector
} from '../../../store/feedback/feedback.selector';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { viteMode } from '../../../utils/utils.tsx';

const LoginPage = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const toast = useAppSelector(toastSelector);
  const envLabel = viteMode();

  const [emailFieldOptions, setEmailFieldOptions] = useState({
    variant: 'default',
    helperText: ''
  });
  const [passwordFieldOptions, setPasswordFieldOptions] = useState({
    variant: 'default',
    helperText: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);
  const fieldError = useAppSelector(filedErrorSelector);

  const handleEmailOnBlur = () => {
    if (emailFieldOptions.variant != 'default')
      setEmailFieldOptions({
        variant: 'default',
        helperText: ''
      });
  };

  const handlePasswordOnBlur = () => {
    if (passwordFieldOptions.variant != 'default')
      setPasswordFieldOptions({
        variant: 'default',
        helperText: ''
      });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email.length > 0 && password.length > 0) {
      try {
        setIsLoading(true);
        await dispatch(authActions.login({ username: email, password })).unwrap();
      } finally {
        setIsLoading(false);
      }
    } else {
      if (email.length === 0) {
        setEmailFieldOptions({
          variant: ColorPropsEnum.DANGER,
          helperText: t('error.emptyEmail', { ns: 'auth' })
        });
      }
      if (password.length === 0) {
        setPasswordFieldOptions({
          variant: ColorPropsEnum.DANGER,
          helperText: t('error.emptyPassword', { ns: 'auth' })
        });
      }
    }
  };

  if (fieldError) {
    setEmailFieldOptions({
      variant: ColorPropsEnum.DANGER,
      helperText: fieldError.message
    });
  }

  const handleCloseErrorMessage = () => {
    dispatch(feedbackActions.resetAttentionBoxError());
  };

  return (
    <>
      {toast && (
        <Toast
          data-cy={`toast-${toast.type}`}
          message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
          onClose={() => {
            dispatch(feedbackActions.resetToast());
          }}
          autoHideDuration={toast.type === 'danger' ? 8000 : 5000}
          type={toast.type}
        />
      )}
      <header className={styles['login-page__header']}>
        <div className="logo--circle"></div>
        <Text
          label={`Circle Admin`}
          type="title"
          size="m"
          className={styles['login-page__header__title']}
        />

        {envLabel && (
          <Chips
            firstLabel={envLabel}
            color={ColorPropsEnum.WHITE}
            className={styles['login-page__header__env']}
          />
        )}
      </header>
      {attentionBoxError && (
        <AttentionBox
          withoutIcon={false}
          mode={ColorPropsEnum.DANGER}
          className={styles['login-page__section__message']}
          onClose={handleCloseErrorMessage}
          text={attentionBoxError.message}
        />
      )}
      <section className={styles['login-page__section']}>
        <Text
          label={t('title', { ns: 'auth' })}
          type="title"
          size="s"
          className={styles['login-page__section__title']}
          bold={true}
        />

        <form
          noValidate
          id="login-page__section__form"
          className={styles['login-page__section__form']}
          onSubmit={handleSubmit}>
          <TextField
            label={t('email', { ns: 'auth' })}
            id="email"
            value={email}
            placeholder="email@circle.com"
            type="email"
            variant={emailFieldOptions.variant}
            helperText={emailFieldOptions.helperText}
            className={styles['login-page__section__form__input']}
            onChange={(evt: Event) => setEmail((evt.target as HTMLInputElement).value)}
            onBlur={handleEmailOnBlur}
          />
          <TextField
            label={t('password', { ns: 'auth' })}
            id="password"
            value={password}
            placeholder={t('password', { ns: 'auth' })}
            type="password"
            variant={passwordFieldOptions.variant}
            helperText={passwordFieldOptions.helperText}
            className={styles['login-page__section__form__input']}
            onChange={(evt: Event) => setPassword((evt.target as HTMLInputElement).value)}
            onBlur={handlePasswordOnBlur}
          />
          <Link
            className="forgotten-password"
            href="/reset-password"
            label={t('forgotPassword', { ns: 'auth' })}
            size="s"
          />

          <div className={styles['login-page__section__form__button']}>
            <Button
              label={t('buttonSubmit', { ns: 'auth' })}
              isLoading={isLoading}
              type="submit"
              className={styles['login-page__section__form__button__submit']}
            />
          </div>
        </form>
        <Divider />
        <div className={styles['login-page__section__signup']}>
          <Text
            className={styles['login-page__section__signup__text']}
            label={t('noAccount', { ns: 'auth' })}
            size="s"
          />
          <Link href="#" label={t('signup', { ns: 'auth' })} size="s" />
        </div>
      </section>
    </>
  );
};

export default LoginPage;
