import { api } from './api';
import { UserInfo } from '../models/user';
import { mapUser } from '../pages/private/users-page/users.tsx';

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation<UserInfo, string>({
      query: (email) => ({
        url: `/users/${email}/reset-password`,
        method: 'POST'
      })
    }),
    createUser: builder.mutation<UserInfo, UserInfo>({
      query: (user) => ({
        url: '/users',
        method: 'POST',
        body: user
      })
    }),
    patchUser: builder.mutation<UserInfo, Partial<UserInfo>>({
      query: ({ email, ...patch }: { email: string; patch: Partial<UserInfo> }) => ({
        url: `/users/${email}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['OneUser']
    }),
    getUser: builder.query<UserInfo, string>({
      query: (email) => ({
        url: `/users/${email}`,
        method: 'GET'
      }),
      providesTags: ['OneUser']
    }),
    deactivateUser: builder.mutation<void, string>({
      query: (email) => ({
        url: `/users/${email}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['OneUser']
    }),
    resetPasswordUser: builder.mutation<void, string>({
      query: (email) => ({
        url: `/users/${email}/reset-password`,
        method: 'POST'
      }),
      invalidatesTags: ['OneUser']
    }),
    getConnectedUser: builder.query<UserInfo, void>({
      query: () => ({
        url: `/users/me`,
        method: 'GET'
      }),
      transformResponse: (response: UserInfo) => {
        return mapUser(response);
      },
      providesTags: ['ConnectedUser']
    })
  })
});

export const {
  useResetPasswordMutation,
  useCreateUserMutation,
  usePatchUserMutation,
  useLazyGetConnectedUserQuery,
  useGetUserQuery,
  useDeactivateUserMutation,
  useResetPasswordUserMutation
} = usersApi;
