import React, { useState } from 'react';
import styles from './establishment-creation-form.module.scss';
import { Box, Button, Dropdown, TextField } from '@platform-storybook/circlestorybook';
import { Establishment } from '../../../../../../../models/user';
import { useTranslation } from 'react-i18next';
import { Country } from '../../../../../../../enum/user';
import { ColorPropsEnum } from '../../../../../../../enum/color.enum';

type Props = {
  establishment: Establishment;
  setSideBarOpenedCallback: (value: Establishment) => void;
  isNewEstablishmentCreated: boolean;
  isWithAddress: boolean;
};

const EstablishmentCreationForm = ({
  establishment,
  setSideBarOpenedCallback,
  isNewEstablishmentCreated,
  isWithAddress
}: Props) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string | undefined>(establishment.name);
  const [address, setAddress] = useState<string | undefined>(establishment.address?.address);
  const [additionalAddress, setAdditionalAddress] = useState<string | undefined>(
    establishment.address?.additionalAddress
  );
  const [zipCode, setZipCode] = useState<string | undefined>(establishment.address?.zipCode);
  const [city, setCity] = useState<string | undefined>(establishment.address?.city);
  const [country, setCountry] = useState<Country | undefined>(establishment.address?.country);
  const [region, setRegion] = useState<string | undefined>(establishment.address?.region);

  const [isShowNameMandatoryMessage, setIsShowNameMandatoryMessage] = useState(false);
  const [isShowAddressMandatoryMessage, setIsShowAddressMandatoryMessage] = useState(false);
  const [isShowZipCodeMandatoryMessage, setIsShowZipCodeMandatoryMessage] = useState(false);
  const [isShowCityMandatoryMessage, setIsShowCityMandatoryMessage] = useState(false);
  const [isShowCountryMandatoryMessage, setIsShowCountryMandatoryMessage] = useState(false);
  const nameMandatory = t('establishmentModal.nameMandatory', { ns: 'user' });
  const addressMandatory = t('establishmentModal.addressMandatory', { ns: 'user' });
  const zipCodeMandatory = t('establishmentModal.zipCodeMandatory', { ns: 'user' });
  const cityMandatory = t('establishmentModal.cityMandatory', { ns: 'user' });
  const countryMandatory = t('establishmentModal.countryMandatory', { ns: 'user' });

  const regionLabel = `${t('establishmentModal.region', { ns: 'user' })} (${t('form.optional', {
    ns: 'common'
  })})`;
  const additionalAddressLabel = `${t('establishmentModal.additionalAddress', {
    ns: 'user'
  })} (${t('form.optional', { ns: 'common' })})`;
  const nameLabel = `${t('establishmentModal.name', { ns: 'user' })} ${!isWithAddress ? '*' : ''}`;
  const namePlaceHolder = t(
    `establishmentModal.placeholder.${isWithAddress ? 'clinic' : 'designCenter'}`,
    { ns: 'user' }
  );

  const buttonLabel = isNewEstablishmentCreated
    ? t('action.update', { ns: 'common' })
    : t('action.create', { ns: 'common' });

  const showMandatoryMessages = () => {
    setIsShowNameMandatoryMessage(!name);
    setIsShowAddressMandatoryMessage(!address);
    setIsShowZipCodeMandatoryMessage(!zipCode);
    setIsShowCityMandatoryMessage(!city);
    setIsShowCountryMandatoryMessage(!country);
  };

  const countries = [
    Object.values(Country).map((country) => {
      return {
        label: t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
        value: country
      };
    })
  ];

  const formIsValid =
    (name && isWithAddress && address && zipCode && city && country) || !isWithAddress;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formIsValid) {
      showMandatoryMessages();
      return;
    }
    establishment.name = name;
    if (address) {
      establishment = {
        ...establishment,
        ...{
          address: {
            ...establishment.address,
            ...{ address, additionalAddress, zipCode, city, country, region }
          }
        }
      };
    }

    setSideBarOpenedCallback(establishment);
  };

  const onSelectCountry = (value: Country) => {
    setCountry(value);
    setIsShowCountryMandatoryMessage(!value);
  };

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit} className={styles['establishment-creation-form']}>
        <TextField
          className={styles['establishment-creation-form__input']}
          id={'name'}
          label={nameLabel}
          value={name}
          type={'text'}
          onBlur={() => setIsShowNameMandatoryMessage(!name)}
          onChange={(evt: Event) => setName((evt.target as HTMLInputElement).value)}
          size={'s'}
          helperText={isShowNameMandatoryMessage ? nameMandatory : undefined}
          variant={isShowNameMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={namePlaceHolder}
        />
        {isWithAddress && (
          <>
            <TextField
              className={styles['establishment-creation-form__input']}
              id={'address'}
              label={t('establishmentModal.address', { ns: 'user' })}
              value={address}
              type={'text'}
              onBlur={() => setIsShowAddressMandatoryMessage(!address)}
              onChange={(evt: Event) => setAddress((evt.target as HTMLInputElement).value)}
              size={'s'}
              helperText={isShowAddressMandatoryMessage ? addressMandatory : undefined}
              variant={
                isShowAddressMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
              }
              placeholder={t('establishmentModal.placeholder.address', { ns: 'user' })}
            />
            <TextField
              className={styles['establishment-creation-form__input']}
              id={'additionalAddress'}
              label={additionalAddressLabel}
              value={additionalAddress}
              type={'text'}
              onChange={(evt: Event) =>
                setAdditionalAddress((evt.target as HTMLInputElement).value)
              }
              size={'s'}
              placeholder={t('establishmentModal.additionalAddress', {
                ns: 'user'
              })}
            />
            <TextField
              className={styles['establishment-creation-form__input-zipcode']}
              id={'zipCode'}
              label={t('establishmentModal.zipCode', { ns: 'user' })}
              value={zipCode}
              type={'text'}
              onBlur={() => setIsShowZipCodeMandatoryMessage(!zipCode)}
              onChange={(evt: Event) => setZipCode((evt.target as HTMLInputElement).value)}
              size={'s'}
              helperText={isShowZipCodeMandatoryMessage ? zipCodeMandatory : undefined}
              variant={
                isShowZipCodeMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
              }
              placeholder={t('establishmentModal.zipCode', { ns: 'user' })}
            />
            <TextField
              className={styles['establishment-creation-form__input']}
              id={'city'}
              label={t('establishmentModal.city', { ns: 'user' })}
              value={city}
              type={'text'}
              onBlur={() => setIsShowCityMandatoryMessage(!city)}
              onChange={(evt: Event) => setCity((evt.target as HTMLInputElement).value)}
              size={'s'}
              helperText={isShowCityMandatoryMessage ? cityMandatory : undefined}
              variant={isShowCityMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
              placeholder={t('establishmentModal.city', { ns: 'user' })}
            />
            <TextField
              id={'region'}
              className={styles['establishment-creation-form__input']}
              label={regionLabel}
              value={region}
              type={'text'}
              onChange={(evt: Event) => setRegion((evt.target as HTMLInputElement).value)}
              size={'s'}
              placeholder={t('establishmentModal.region', { ns: 'user' })}
            />
            <Dropdown
              data={countries}
              value={country}
              label={t('establishmentModal.country', { ns: 'user' })}
              variant={
                isShowCountryMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
              }
              onChange={(value: Country) => onSelectCountry(value)}
              placeholder={t('userForm.choose', { ns: 'user' })}
              helperText={isShowCountryMandatoryMessage ? countryMandatory : undefined}></Dropdown>
          </>
        )}
        <div className="form__submit-button">
          <Button label={buttonLabel} type="submit" />
        </div>
      </form>
    </Box>
  );
};

export default EstablishmentCreationForm;
