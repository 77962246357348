import styles from '../create-component-page.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Fieldset,
  Dropdown,
  Button,
  TextField,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import useForm from '../../../../../../utils/useForm';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  componentTypeDropdownSelector,
  manufacturingProcessDropdownSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { componentsActions } from '../../../../../../store/components/components.reducers';
import { componentSelector } from '../../../../../../store/components/components.selectors';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { StringObject, UnknownObject } from '../../../../../../models/common';
import { ComponentType } from '../../../../../../enum/component-type.ts';
import { ComponentCreationForm } from '../../../../../../models/component.tsx';
import { useGetCommonTypesQuery } from '../../../../../../services/common-types-rtkq.services.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { SVGLayerEnum } from '../../../../../../enum/component.ts';

type Props = {
  nextCallback: () => void;
};

const IdentificationForm = ({ nextCallback }: Props) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();

  const component = useAppSelector(componentSelector);
  const { isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const componentTypeDropdown = useAppSelector(componentTypeDropdownSelector);
  const manufacturingProcessDropdown = useAppSelector(manufacturingProcessDropdownSelector);
  const saveComponentInfo = () => {
    dispatch(componentsActions.setComponent({ ...component, ...values } as ComponentCreationForm));
  };

  const onSubmit = () => {
    saveComponentInfo();
    nextCallback();
  };

  const isUppercaseAndUnderscore = (stringToCheck: string) => {
    // Regular expression to check for only uppercase letters and underscores
    const regex = /^[A-Z_]+$/;
    return regex.test(stringToCheck);
  };

  const validateCallback = (values: UnknownObject) => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (key === 'svgLayer' && values[key] && !isUppercaseAndUnderscore(values[key] as string)) {
        newErrors[key] = 'format';
      }
      if (!['svgLayer', 'manufacturingProcess'].includes(key) && !values[key]) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, handleSubmit, handleSelect, handleBlur, handleChange } = useForm(
    {
      componentType: component?.componentType as ComponentType,
      labelFr: component?.labelFr ?? '',
      labelEn: component?.labelEn ?? '',
      svgLayer: component?.svgLayer ?? '',
      manufacturingProcess: component?.manufacturingProcess ?? undefined
    },
    onSubmit,
    validateCallback
  );

  return (
    <form onSubmit={handleSubmit} className={styles['create-component-page__form']}>
      <Fieldset size="m" className={styles['create-component-page__form__fieldset']}>
        <Dropdown
          label={t('components.componentForm.componentTypeInput.label')}
          data={areCommonTypesLoading ? [] : [componentTypeDropdown]}
          isLoading={areCommonTypesLoading}
          value={values.componentType}
          placeholder={t('components.componentForm.choose')}
          onChange={(newValue: number) => {
            dispatch(componentsActions.resetComponent());
            handleSelect(newValue, 'componentType');
          }}
          helperText={
            errors.componentType && t('components.componentForm.componentTypeInput.error')
          }
          variant={errors.componentType ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          className={styles['create-component-page__form__fieldset__input']}
        />
        <TextField
          value={values.labelFr}
          helperText={
            errors.labelFr
              ? t('components.componentForm.labelFrInput.error')
              : t('components.componentForm.labelFrInput.helperText')
          }
          id="labelFr"
          name="labelFr"
          placeholder="Châssis Titane"
          label={t('components.componentForm.labelFrInput.label')}
          onBlur={handleBlur}
          onChange={handleChange}
          className={styles['create-component-page__form__fieldset__input']}
          variant={errors.labelFr ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
        />
        <TextField
          value={values.labelEn}
          helperText={
            errors.labelEn
              ? t('components.componentForm.labelEnInput.error')
              : t('components.componentForm.labelEnInput.helperText')
          }
          className={styles['create-component-page__form__fieldset__input']}
          id="labelEn"
          name="labelEn"
          placeholder="Titanium frame"
          label={t('components.componentForm.labelEnInput.label')}
          onBlur={handleBlur}
          onChange={handleChange}
          variant={errors.labelEn ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
        />
        <div className={styles['create-component-page__form__fieldset__svg']}>
          <TextField
            value={values.svgLayer}
            helperText={
              errors.svgLayer
                ? t(`components.componentForm.svgLayerInput.${errors.svgLayer}`)
                : t('components.componentForm.svgLayerInput.helperText')
            }
            className={styles['create-component-page__form__fieldset__input']}
            id="svgLayer"
            name="svgLayer"
            data-cy="svgLayer"
            placeholder="FRAME"
            label={t('components.componentForm.svgLayerInput.label')}
            onBlur={handleBlur}
            onChange={handleChange}
            variant={errors.svgLayer ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          />
          <Tooltip isClickable={true}>
            <FontAwesomeIcon
              className={styles['create-component-page__form__fieldset__svg__icon']}
              icon={faCircleInfo}
              color={ColorPropsEnum.GREY}
            />
            <TooltipContent>{Object.values(SVGLayerEnum).join(' | ')}</TooltipContent>
          </Tooltip>
        </div>
        <Dropdown
          label={t('components.componentForm.manufacturingProcess.label')}
          data={areCommonTypesLoading ? [] : [manufacturingProcessDropdown]}
          isLoading={areCommonTypesLoading}
          value={values.manufacturingProcess}
          placeholder={t('components.componentForm.choose')}
          onChange={(newValue: number) => {
            dispatch(componentsActions.resetComponent());
            handleSelect(newValue, 'manufacturingProcess');
          }}
          helperText={t('components.componentForm.manufacturingProcess.helperText')}
          className={styles['create-component-page__form__fieldset__input']}
          data-cy="manufacturingProcess"
        />
      </Fieldset>
      <div className="form__submit-button form__submit-button--right">
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default IdentificationForm;
