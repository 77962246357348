import styles from '../../create-user-page.module.scss';
import { Button, Fieldset, RadioList, TextField } from '@platform-storybook/circlestorybook';
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { userSelector } from '../../../../../../store/users/users.selectors';
import { usersActions } from '../../../../../../store/users/users.reducers';
import { Language, DentalNotation, DentistRole } from '../../../../../../enum/user';
import { dentalNotations, isDentistRole, languages } from '../../../../../../utils/utils';
import useForm from '../../../../../../utils/useForm';
import { StringObject } from '../../../../../../models/common';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const CustomizationForm = ({ nextCallback, previousCallback }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  const deliveryDelayMandatory = i18next.t('userForm.deliveryDelayMandatory', { ns: 'user' });

  const isDentistOrAssistantRole = isDentistRole(user?.role as DentistRole);

  const saveCustomizationInfo = () => {
    dispatch(
      usersActions.setUser({
        ...user,
        ...values,
        deliveryDelay:
          isDentistRole(user?.role as DentistRole) &&
          (values.deliveryDelay || values.deliveryDelay === 0)
            ? +(values.deliveryDelay as number)
            : undefined
      })
    );
  };

  const onSubmit = () => {
    saveCustomizationInfo();
    nextCallback();
  };

  const handlePreviousButton = () => {
    saveCustomizationInfo();
    previousCallback();
  };

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (
        (!values[key] && key !== 'deliveryDelay') ||
        (key === 'deliveryDelay' && isDentistOrAssistantRole && values[key] === undefined)
      ) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, handleSubmit, handleSelect, handleChange, handleBlur } = useForm(
    {
      dentalNotation: user?.dentalNotation ?? DentalNotation.ISO,
      language: user?.language ?? Language.fr_FR,
      deliveryDelay: user?.deliveryDelay || user?.deliveryDelay === 0 ? +user.deliveryDelay : 2
    },
    onSubmit,
    validateAllFieldsRequired
  );

  return (
    <form onSubmit={handleSubmit} className={styles['create-user-form__form']}>
      <Fieldset size="m" className={styles['create-user-form__form__fieldset']}>
        <RadioList
          title={i18next.t('userForm.dentalNotation', { ns: 'user' })}
          options={dentalNotations}
          name="dentalNotation"
          selectedValue={values.dentalNotation}
          onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
          className={styles['create-user-form__form__input']}
        />
        <RadioList
          title={i18next.t('userForm.language', { ns: 'user' })}
          options={languages}
          name="language"
          selectedValue={values.language}
          onClick={(newValue: Language) => handleSelect(newValue, 'language')}
          className={styles['create-user-form__form__input']}
        />
        {isDentistOrAssistantRole && (
          <TextField
            label={i18next.t('userForm.deliveryDelay', { ns: 'user' })}
            type="number"
            id="deliveryDelay"
            name="deliveryDelay"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.deliveryDelay ? deliveryDelayMandatory : undefined}
            variant={errors.deliveryDelay ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-user-form__form__input']}
            placeholder="2"
            min="0"
            value={values.deliveryDelay}
          />
        )}
      </Fieldset>
      <div className="form__buttons">
        <Button
          label={i18next.t('action.previous', { ns: 'common' })}
          category="secondary"
          onClick={handlePreviousButton}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={i18next.t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};
export default CustomizationForm;
