export enum ToothStratificationTechniqueEnum {
  FULL = 'FULL',
  CUTBACK = 'CUTBACK'
}

export enum ImplantAttachmentEnum {
  BALL = 'BALL',
  LOCATOR = 'LOCATOR',
  MICRO_CONNECTOR = 'MICRO_CONNECTOR',
  CLIP = 'CLIP',
  EQUATOR = 'EQUATOR'
}

export enum SVGLayerEnum {
  ABUTMENT = 'ABUTMENT',
  ABUTMENT_MULTI_UNIT = 'ABUTMENT_MULTI_UNIT',
  ADJUSTED = 'ADJUSTED',
  ADJUSTED_METAL = 'ADJUSTED_METAL',
  ATTACHMENT_BALL = 'ATTACHMENT_BALL',
  ATTACHMENT_CLIP = 'ATTACHMENT_CLIP',
  ATTACHMENT_EQUATOR = 'ATTACHMENT_EQUATOR',
  ATTACHMENT_LOCATOR = 'ATTACHMENT_LOCATOR',
  ATTACHMENT_MICRO_CONNECTOR = 'ATTACHMENT_MICRO_CONNECTOR',
  BACKPLATE = 'BACKPLATE',
  BRIDGE = 'BRIDGE',
  DOUBLE_BAR = 'DOUBLE_BAR',
  DOUBLE_ZONES = 'DOUBLE_ZONES',
  DRILLING_SYSTEM = 'DRILLING_SYSTEM',
  ENDOCROWN = 'ENDOCROWN',
  EXTRACT = 'EXTRACT',
  FRAME = 'FRAME',
  GINGIVA = 'GINGIVA',
  GUARD_INJECTION = 'GUARD_INJECTION',
  GUARD_LINE = 'GUARD_LINE',
  GUARD_ROUND = 'GUARD_ROUND',
  GUARD_TOOTH = 'GUARD_TOOTH',
  IMPLANT = 'IMPLANT',
  INLAY = 'INLAY',
  INLAYCORE = 'INLAYCORE',
  LINES = 'LINES',
  MISSING = 'MISSING',
  ONLAY = 'ONLAY',
  OVERLAY = 'OVERLAY',
  PIERCING_SYSTEM = 'PIERCING_SYSTEM',
  REINFORCEMENT_SCREW_RETAINED = 'REINFORCEMENT_SCREW_RETAINED',
  SCREW_HEALING = 'SCREW_HEALING',
  SCREW_RETAINED = 'SCREW_RETAINED',
  SIMPLE_BAR = 'SIMPLE_BAR',
  SLEEVE = 'SLEEVE',
  STUMP = 'STUMP',
  TOOTH = 'TOOTH',
  VENEER = 'VENEER',
  WAX_ON_HARD_BASE = 'WAX_ON_HARD_BASE'
}

export enum ToothShadeEnum {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A3_5 = 'A3_5',
  A4 = 'A4',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  D2 = 'D2',
  D3 = 'D3',
  D4 = 'D4',
  METAL = 'METAL'
}

export enum GingivaShadeEnum {
  T3 = 'T3',
  T5 = 'T5',
  T7 = 'T7'
}
