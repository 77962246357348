import { ProductsReducer } from '../../models/product';
import { getCircleSoftwareCompatibility } from '../../utils/utils';

interface ReducerState {
  productState: ProductsReducer;
}

export const productSelector = (state: ReducerState) => state.productState.product;

export const circleSoftwareCompatibilitySelector = (state: ReducerState) =>
  getCircleSoftwareCompatibility(state.productState?.product);

export const circleSoftwareCompatibilityDetailsSelector = (state: ReducerState) =>
  getCircleSoftwareCompatibility(state.productState?.productDetails);
