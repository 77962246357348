import { useState } from 'react';
import styles from './tags-form.module.scss';
import { Button, CheckboxList, Box, AttentionBox, Text } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import useForm from '../../../../utils/useForm';
import { UserInfo } from '../../../../models/user';
import { getMessageError } from '../../../../utils/utils';
import { CheckboxStringItem } from '../../../../models/form';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { usePatchUserMutation } from '../../../../services/users-rtkq.services';

type props = {
  onSubmitCallback: () => void;
  user: UserInfo;
};

const TagsForm = ({ onSubmitCallback, user }: props) => {
  const { t } = useTranslation(['user']);
  const [error, setError] = useState<string | null>(null);

  const [patchUser, { isLoading: isLoadingPatchUser }] = usePatchUserMutation();

  const mapTagsForCheckbox = (): Array<CheckboxStringItem> => {
    if (user?.laboratory?.tags && (user?.laboratory?.tags || []).length > 0) {
      return user.laboratory.tags.map((tag: string) => {
        return {
          isChecked: 'tags' in user ? user.tags?.includes(tag) : false,
          label: tag,
          value: tag
        };
      });
    }
    return [];
  };

  const mapCheckboxToBody = (dataCheckbox: Array<CheckboxStringItem>) => {
    return dataCheckbox
      .filter((data: CheckboxStringItem) => data.isChecked)
      .map((data: CheckboxStringItem) => data.value);
  };

  const onSubmit = () => {
    patchUser({
      tags: mapCheckboxToBody(values.tags as Array<CheckboxStringItem>),
      email: user.email
    })
      .unwrap()
      .then(() => {
        onSubmitCallback();
      })
      .catch((error) => setError(getMessageError(error)));
  };

  const { values, handleCheck, handleSubmit } = useForm(
    {
      tags: mapTagsForCheckbox()
    },
    onSubmit
  );

  return (
    <>
      {error && (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={error}
          className={styles['tags-form__attention-box']}
          onClose={() => setError(null)}
        />
      )}
      <Box color={ColorPropsEnum.WHITE} className={styles['tags-form__form']}>
        {
          <form onSubmit={handleSubmit}>
            {Object.keys(user?.laboratory?.tags || []).length > 0 ? (
              <>
                <CheckboxList
                  title={t('datagrid.tagsModalModal.label')}
                  name="tags"
                  data={values.tags}
                  onClick={handleCheck}
                />
                <div className="form__submit-button">
                  <Button
                    label={t('datagrid.tagsModalModal.action')}
                    isLoading={isLoadingPatchUser}
                    type="submit"
                  />
                </div>
              </>
            ) : (
              <Text label={t('datagrid.tagsModalModal.notag')} />
            )}
          </form>
        }
      </Box>
    </>
  );
};

export default TagsForm;
