import { useCallback, useEffect, useState } from 'react';
import { Button, Text } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { CreateProductNavProps } from '../CreateProductPage';
import styles from '../create-product-page.module.scss';
import useForm from '../../../../../../utils/useForm';
import DatagridFeature from '../../../../../../features/datagrid/DatagridFeature';
import {
  colCheckBox,
  colFamily,
  colProductLabel,
  computeDatagridDatasource,
  getFilters,
  hasCompatibilities
} from './compatibilities';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types';
import { datagridSettingsActions } from '../../../../../../store/datagrids-settings/datagrids-settings.reducers';
import { LoadDataProps, SettingsReducer } from '../../../../../../models/datagrid';
import { compatibilitiesDatagridStateSelector } from '../../../../../../store/datagrids-settings/datagrids-settings.selectors';
import { productSelector } from '../../../../../../store/products/products.selectors';
import { productsActions } from '../../../../../../store/products/products.reducers';
import {
  Product,
  ProductCompatibilityCreation,
  ProductCreationForm
} from '../../../../../../models/product';
import { getAllActiveProducts } from '../../../../../../services/products.services';
import { getSort } from '../../../../../../features/datagrid/datagrid.utils.ts';
import { TypeSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { useGetCommonTypesQuery } from '../../../../../../services/common-types-rtkq.services.ts';

interface HeaderValues {
  orderCompatibility: boolean;
  toothCompatibility: boolean;
  archCompatibility: boolean;
}

const CompatibilityForm = ({ previousStepCallback, nextStepCallback }: CreateProductNavProps) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const datagridSettings: SettingsReducer = useAppSelector(compatibilitiesDatagridStateSelector);
  const { data: commonTypes } = useGetCommonTypesQuery();
  const currentProduct = useAppSelector(productSelector);
  const [checkboxHeadersValues, setCheckboxHeadersValues] = useState<HeaderValues>({
    orderCompatibility: false,
    toothCompatibility: false,
    archCompatibility: false
  });
  const [filteredActiveProducts, setFilteredActiveProducts] = useState<Product[]>([]);

  const loadData = async ({ sortInfo, filterValue }: LoadDataProps) => {
    const filters = getFilters(filterValue);
    const sort = getSort(sortInfo);

    return getAllActiveProducts(filters, sort)
      .then((products) => {
        setFilteredActiveProducts(products.data.data);
        return computeDatagridDatasource(currentProduct as ProductCreationForm, products.data.data);
      })
      .catch(() => {
        return [];
      });
  };

  const dataSource = useCallback(loadData, [commonTypes, currentProduct]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(datagridSettingsActions.setCompatibilitiesDatagridFilters(newFilterValue));
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    const newSortInfoObject = newSortInfo as TypeSingleSortInfo;
    dispatch(datagridSettingsActions.setCompatibilitiesDatagridSort(newSortInfoObject));
  };

  // Here we will count the number of order/arch/tooth compatibilities among filtered products
  // to compute the value of our checkboxes in headers
  const computeCheckboxHeadersValues = () => {
    let orderCount = 0;
    let archCount = 0;
    let toothCount = 0;

    // If we don't have everything we need to compute, everything is false (unchecked)
    if (!(currentProduct?.productCompatibilities && filteredActiveProducts.length)) {
      setCheckboxHeadersValues({
        orderCompatibility: false,
        toothCompatibility: false,
        archCompatibility: false
      });
      return;
    }

    filteredActiveProducts.forEach((activeProduct) => {
      const matchingCompatibility = currentProduct?.productCompatibilities?.find(
        (productCompatibility) => {
          return productCompatibility.compatibleProduct.id === activeProduct.id;
        }
      );

      // If we don't find an existing compatibility for a product, then there is at least one non-compatible-product
      // then checkboxes in header will be unchecked
      if (!matchingCompatibility) {
        setCheckboxHeadersValues({
          orderCompatibility: false,
          toothCompatibility: false,
          archCompatibility: false
        });
        return;
      }
      // Else, we count each type of compatibility
      orderCount++;
      if (matchingCompatibility.archCompatibility) {
        archCount++;
      }
      if (matchingCompatibility.toothCompatibility) {
        toothCount++;
      }
    });

    // In the end, the value of a checkbox in header is checked only if everything is checked in the column
    const newHeaderValues: HeaderValues = {
      orderCompatibility: orderCount === filteredActiveProducts.length,
      archCompatibility: archCount === filteredActiveProducts.length,
      toothCompatibility: toothCount === filteredActiveProducts.length
    };

    setCheckboxHeadersValues(newHeaderValues);
  };

  const initializeCompatibility = (
    activeProductId: number,
    headerKey: string,
    newColumnValue: boolean
  ): ProductCompatibilityCreation => {
    const newCompatibility: ProductCompatibilityCreation = {
      compatibleProduct: { id: activeProductId },
      archCompatibility: false,
      toothCompatibility: false
    };

    if (headerKey === 'archCompatibility') {
      newCompatibility.archCompatibility = newColumnValue;
    } else if (headerKey === 'toothCompatibility') {
      newCompatibility.toothCompatibility = newColumnValue;
      newCompatibility.archCompatibility = newColumnValue ?? newCompatibility.archCompatibility;
    }
    return newCompatibility;
  };

  const handleHeaderCheckboxClick = (headerKey: string) => {
    const isChecked = !checkboxHeadersValues[headerKey as keyof typeof checkboxHeadersValues];
    const updatedCompatibilities: ProductCompatibilityCreation[] = [];
    const filteredActiveProductsIds: number[] = filteredActiveProducts.map(
      (filteredActiveProduct) => filteredActiveProduct.id
    ) as number[];
    // unupdatedCompatibilities contains existing compatibilities related to product not in the current filter
    const unupdatedCompatibilities = (currentProduct?.productCompatibilities ?? []).filter(
      (productCompatibility) => {
        return !filteredActiveProductsIds.includes(productCompatibility.compatibleProduct.id);
      }
    );

    // We will then browse all filtered product to compute their compatibilities
    filteredActiveProducts.forEach((activeProduct) => {
      // Define if the current product will have an entry in the array of compatibilities of the product being created
      let mustAddNewCompatibility = isChecked;

      // Initiliaze a compatibility compute fron which column we clicked, and the new value of the said column
      // If no compatibility exists for a product, and if mustAddNewCompatibility is true, it will be aded as is
      let currentCompatibility = initializeCompatibility(
        activeProduct.id as number,
        headerKey,
        isChecked
      );

      // is there an existing compatibility for this product already ?
      const matchingCompatibility = currentProduct?.productCompatibilities?.find(
        (productCompatibility) => {
          return productCompatibility.compatibleProduct.id === activeProduct?.id;
        }
      );
      if (matchingCompatibility) {
        // if yes, currentCompatibility becomes matchingCompatibility, and we will update it
        currentCompatibility = { ...matchingCompatibility };
        if (headerKey === 'archCompatibility') {
          // A compatibility exists and orderCompatibility wasn't the column clicked, then we will need this entry
          mustAddNewCompatibility = true;
          currentCompatibility.archCompatibility = isChecked;
          // If archCompatibility becomes false, so does toothCompatibility
          if (!isChecked) {
            currentCompatibility.toothCompatibility = false;
          }
        } else if (headerKey === 'toothCompatibility') {
          // A compatibility exists and orderCompatibility wasn't the column clicked, then we will need this entry
          mustAddNewCompatibility = true;
          currentCompatibility.toothCompatibility = isChecked;
          // If toothCompatibility becomes true, so does archCompatibility
          if (isChecked) {
            currentCompatibility.archCompatibility = true;
          }
        }
      }
      if (mustAddNewCompatibility) {
        updatedCompatibilities.push(currentCompatibility);
      }
    });

    dispatch(
      productsActions.setProduct({
        ...currentProduct,
        productCompatibilities: [...updatedCompatibilities, ...unupdatedCompatibilities]
      } as ProductCreationForm)
    );
  };

  useEffect(() => {
    computeCheckboxHeadersValues();
  }, [currentProduct, filteredActiveProducts]);

  const handleArchCheckboxClick = (value: boolean) => {
    return {
      archCompatibility: value,
      toothCompatibility: false
    };
  };

  const handleToothCheckboxClick = (
    productCompatibility: ProductCompatibilityCreation,
    value: boolean
  ) => {
    let newArchCompatibility = productCompatibility.archCompatibility;
    const newToothCompatibility = value;

    // If toothCompatibility becomes true, so does archCompatibility
    if (value) {
      newArchCompatibility = value;
    }

    return {
      archCompatibility: newArchCompatibility,
      toothCompatibility: newToothCompatibility
    };
  };

  const handleCheckboxClick = (productId: number, column: string, value: boolean) => {
    const updatedCompatibilities: ProductCompatibilityCreation[] = [];
    // Define if the product with id productId will have an entry in the array of compatibilities of the product being created
    let mustAddNewCompatibility = true;
    // Initiliaze a compatibility compute fron which column we clicked, and the new value of the said column
    // If no compatibility exists for a product, and if mustAddNewCompatibility is true, it will be aded as is
    let newCompatibility = initializeCompatibility(productId, column, value);

    currentProduct?.productCompatibilities?.forEach((productCompatibility) => {
      if (productCompatibility.compatibleProduct.id === productId) {
        switch (column) {
          case 'orderCompatibility':
            // Here, a compatibility was found and we update orderCompatibility
            // Then it means that it is becoming false (else we wouldn't have found any)
            // Then, we only need to make sure it is not added to the new array
            mustAddNewCompatibility = false;
            break;
          case 'archCompatibility':
            newCompatibility = { ...newCompatibility, ...handleArchCheckboxClick(value) };
            break;
          case 'toothCompatibility':
            newCompatibility = {
              ...newCompatibility,
              ...handleToothCheckboxClick(productCompatibility, value)
            };
            break;
          default:
            break;
        }
      } else {
        updatedCompatibilities.push(productCompatibility);
      }
    });

    if (mustAddNewCompatibility) {
      updatedCompatibilities.push(newCompatibility);
    }
    dispatch(
      productsActions.setProduct({
        ...currentProduct,
        productCompatibilities: updatedCompatibilities
      } as ProductCreationForm)
    );
  };

  const { handleSubmit } = useForm({}, () => {
    if (nextStepCallback) nextStepCallback();
  });

  return (
    <form onSubmit={handleSubmit} className={styles['create-product-page__compatibility-form']}>
      <div className={styles['create-product-page__compatibility-form__actions']}>
        <div className={styles['create-product-page__compatibility-form__actions--counter']}>
          {currentProduct && hasCompatibilities(currentProduct) && (
            <Text
              size="s"
              label={t('products.productForm.compatibilities.counter', {
                count: currentProduct?.productCompatibilities?.length
              })}
              color={'default'}></Text>
          )}
          {currentProduct && !hasCompatibilities(currentProduct) && (
            <Text
              size="s"
              label={t('products.productForm.compatibilities.helper')}
              color={'default'}></Text>
          )}
        </div>
        <div className={styles['create-product-page__compatibility-form__actions--buttons']}>
          <Button
            size="s"
            category="tertiary"
            label={t('datagrid.removeAllFilters', { ns: 'common' })}
            onClick={() => {
              dispatch(datagridSettingsActions.resetCompatibilitiesDatagridFilters());
            }}
          />
          <Button
            size="s"
            category="tertiary"
            label={t('datagrid.resetAllCompatibilities', { ns: 'compatibilities' })}
            onClick={() => {
              dispatch(
                productsActions.setProduct({
                  ...currentProduct,
                  productCompatibilities: []
                } as ProductCreationForm)
              );
            }}
          />
        </div>
      </div>
      {commonTypes && (
        <DatagridFeature
          key="compatibilities"
          columns={[
            colProductLabel,
            colFamily(commonTypes),
            colCheckBox({
              column: 'toothCompatibility',
              onCheckboxClick: handleCheckboxClick,
              onHeaderCheckboxClick: handleHeaderCheckboxClick,
              headerChecked: checkboxHeadersValues.toothCompatibility,
              width: 110
            }),
            colCheckBox({
              column: 'archCompatibility',
              onCheckboxClick: handleCheckboxClick,
              onHeaderCheckboxClick: handleHeaderCheckboxClick,
              headerChecked: checkboxHeadersValues.archCompatibility,
              width: 110
            }),
            colCheckBox({
              column: 'orderCompatibility',
              onCheckboxClick: handleCheckboxClick,
              onHeaderCheckboxClick: handleHeaderCheckboxClick,
              headerChecked: checkboxHeadersValues.orderCompatibility,
              width: 130
            })
          ]}
          filterValue={datagridSettings.filters}
          onFilterValueChange={onFilterValueChange}
          defaultSortInfo={datagridSettings.sort}
          onSortInfoChange={onSortInfoChange}
          dataSource={dataSource}
          className={styles['create-product-page__compatibility-form__datagrid']}
          style={{ minHeight: 'calc(100% - 4.5rem)' }}
          livePagination={false}
          pagination={false}
        />
      )}
      <div className="form__buttons">
        <Button
          label={t('action.previous', { ns: 'common' })}
          category="secondary"
          onClick={previousStepCallback}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default CompatibilityForm;
