import { useState } from 'react';
import { AttentionBox, Box, Button, Text, TextField } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import useForm from '../../../../utils/useForm';
import { getMessageError } from '../../../../utils/utils';
import styles from './export-manufactured-orders-form.module.scss';
import { exportManufacturedOrders } from '../../../../services/manufacturing-orders.services';
import FileSaver from 'file-saver';
import { HttpStatusCode } from 'axios';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { useAppDispatch } from '../../../../store/hooks';
import moment from 'moment';
import { ColorPropsEnum } from '../../../../enum/color.enum';

type props = {
  onSubmitCallback: (keepOpened: boolean) => void;
};
const ExportManufacturedOrdersForm = ({ onSubmitCallback }: props) => {
  const { t } = useTranslation(['orders']);
  const [loadingExport, setLoadingExport] = useState(false);

  const [error, setError] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();

  const previousMonth = moment(new Date()).subtract(1, 'months');
  const firstDayOfPreviousMonth = previousMonth.startOf('month').format('YYYY-MM-DD');
  const lastDayOfPreviousMonth = previousMonth.endOf('month').format('YYYY-MM-DD');

  const onSubmit = () => {
    setLoadingExport(true);
    dispatch(
      feedbackActions.setToast({
        message: t('datagrid.export.loading'),
        type: 'info'
      })
    );
    const startDate: Date = values.startManufacturingStartRange as Date;
    const endDate: Date = values.startManufacturingEndRange as Date;

    exportManufacturedOrders(startDate, endDate)
      .then((streamableFileObject) => {
        let keepOpened = false;
        if (streamableFileObject.status === HttpStatusCode.Ok) {
          const tempName = `export-orders-${moment(startDate).format('DD_MM_YYYY')}-${moment(
            endDate
          ).format('DD_MM_YYYY')}.xlsx`;

          FileSaver.saveAs(new Blob([streamableFileObject.data as BlobPart]), tempName);
        } else if (streamableFileObject.status === HttpStatusCode.NoContent) {
          dispatch(
            feedbackActions.setToast({
              message: t('datagrid.export.noContent'),
              type: 'info'
            })
          );
          keepOpened = true;
        }
        onSubmitCallback(keepOpened);
      })
      .catch((err) => {
        setError(getMessageError(err));
      })
      .finally(() => setLoadingExport(false));
  };
  const { values, handleChange, handleBlur, handleSubmit } = useForm(
    {
      startManufacturingStartRange: firstDayOfPreviousMonth,
      startManufacturingEndRange: lastDayOfPreviousMonth
    },
    onSubmit
  );

  return (
    <>
      {error && (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={error}
          className={styles['export-form__attention-box']}
          onClose={() => setError(undefined)}
        />
      )}
      <Box color={ColorPropsEnum.WHITE} className={styles['export-form__form']}>
        <form data-cy="exportManufacturedOrdersForm" onSubmit={handleSubmit}>
          <Text label={t('datagrid.export.rangeDate')} size="m" type="text" bold={true} />
          <div className={styles['export-form__form__textfields']}>
            <TextField
              data-cy="exportManufacturedOrdersFormStartDate"
              className={styles['export-form__form__textfields__textfield']}
              id="startManufacturingStartRange"
              name="startManufacturingStartRange"
              value={values.startManufacturingStartRange}
              max={values.startManufacturingEndRange}
              isRequired
              onBlur={handleBlur}
              onChange={handleChange}
              size="m"
              type="date"
            />
            <TextField
              data-cy="exportManufacturedOrdersFormEndDate"
              className={styles['export-form__form__textfields__textfield']}
              id="startManufacturingEndRange"
              name="startManufacturingEndRange"
              value={values.startManufacturingEndRange}
              min={values.startManufacturingStartRange}
              isRequired
              onBlur={handleBlur}
              onChange={handleChange}
              size="m"
              type="date"
            />
          </div>
          <div className="form__submit-button">
            <Button
              data-cy="exportManufacturedOrdersFormSubmit"
              label={t('datagrid.export.export')}
              isLoading={loadingExport}
              type="submit"
            />
          </div>
        </form>
      </Box>
    </>
  );
};

export default ExportManufacturedOrdersForm;
