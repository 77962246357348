import {
  Box,
  Button,
  TextField,
  CheckboxList,
  Skeleton
} from '@platform-storybook/circlestorybook';
import { UserInfo } from '../../../../../models/user';
import styles from './user-forms.module.scss';
import { isDentistRole } from '../../../../../utils/utils';
import useForm from '../../../../../utils/useForm';
import { StringObject } from '../../../../../models/common';
import { CheckboxStringItem } from '../../../../../models/form';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { useGetUserQuery } from '../../../../../services/users-rtkq.services';

type Props = {
  email: string;
  saveCallback: (user: Partial<UserInfo>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const IdentificationForm = ({ email, saveCallback, closeSideNavCallback }: Props) => {
  const { t } = useTranslation(['user']);

  // this will not refetch, it just reselects
  const { data: user, isLoading: isUserLoading } = useGetUserQuery(email);

  const firstNameMandatory = t('userForm.firstNameMandatory');
  const lastNameMandatory = t('userForm.lastNameMandatory');
  const phoneMandatory = t('userForm.phoneMandatory');

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (
        (!values[key] && key !== 'phoneNumber') ||
        (key === 'phoneNumber' && user?.role && isDentistRole(user.role) && !values[key])
      ) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const mapCheckboxToBody = (dataCheckbox: Array<CheckboxStringItem>) => {
    return dataCheckbox
      .filter((data: CheckboxStringItem) => data.isChecked)
      .map((data: CheckboxStringItem) => data.value);
  };

  const mapTagsForCheckbox = (tags: string[]): Array<CheckboxStringItem> => {
    if (tags.length > 0) {
      return tags.map((tag: string) => {
        return {
          isChecked: user && 'tags' in user ? user.tags?.includes(tag) : false,
          label: tag,
          value: tag
        };
      });
    }
    return [];
  };

  const onSubmit = () => {
    if (user?.role && user?.email) {
      saveCallback(
        {
          firstName: values.firstName as string,
          lastName: values.lastName as string,
          ...(isDentistRole(user.role) && {
            tags: mapCheckboxToBody(values.tags as Array<CheckboxStringItem>)
          }),
          phoneNumber:
            isDentistRole(user.role) && (values.phoneNumber as string)
              ? (values.phoneNumber as string)
              : undefined
        },
        user?.email
      );
    }

    closeSideNavCallback(false);
  };

  const { values, errors, handleSubmit, handleCheck, handleBlur, handleChange } = useForm(
    {
      ...(user?.role &&
        isDentistRole(user.role) && { tags: mapTagsForCheckbox(user?.laboratory?.tags || []) }),
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNumber: user?.phoneNumber || ''
    },
    onSubmit,
    validateAllFieldsRequired
  );

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit}>
        <TextField
          id={'firstName'}
          name="firstName"
          label={t('userForm.firstName')}
          value={values.firstName}
          onBlur={handleBlur}
          type={'text'}
          onChange={handleChange}
          helperText={errors.firstName ? firstNameMandatory : undefined}
          variant={errors.firstName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder="John"
          className={styles['user-forms__input']}
        />
        <TextField
          id={'lastName'}
          name="lastName"
          label={t('userForm.lastName')}
          value={values.lastName}
          onBlur={handleBlur}
          onChange={handleChange}
          type={'text'}
          helperText={errors.lastName ? lastNameMandatory : undefined}
          variant={errors.lastName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder="Smith"
          className={styles['user-forms__input']}
        />
        {user?.role && isDentistRole(user.role) && (
          <TextField
            label={t('userForm.phone')}
            id={'phone'}
            name="phoneNumber"
            value={values.phoneNumber}
            type={'tel'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors.phoneNumber ? phoneMandatory : t('userForm.helperText.phone')}
            variant={errors.phoneNumber ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            placeholder={t('userForm.placeholder.phone')}
            className={styles['user-forms__input']}
          />
        )}

        {user?.role && isDentistRole(user.role) && isUserLoading && (
          <div>
            <Skeleton type="text" className={styles['user-forms__skeleton']} />
            <Skeleton type="text" className={styles['user-forms__skeleton']} />
            <Skeleton type="text" />
          </div>
        )}
        {user?.role &&
          isDentistRole(user.role) &&
          !isUserLoading &&
          Object.keys(user?.laboratory?.tags || []).length > 0 &&
          Object.keys(user?.laboratory?.tags || []).length > 0 && (
            <CheckboxList
              title={t('datagrid.tagsModalModal.label')}
              name="tags"
              data={values.tags}
              onClick={handleCheck}
            />
          )}
        {user?.role &&
          isDentistRole(user.role) &&
          !isUserLoading &&
          Object.keys(user?.laboratory?.tags || []).length === 0 &&
          t('datagrid.tagsModalModal.notag')}

        <div className="form__submit-button">
          <Button
            label={t('action.update', { ns: 'common' })}
            data-cy="user-forms-identification-submit"
            type="submit"
          />
        </div>
      </form>
    </Box>
  );
};
export default IdentificationForm;
