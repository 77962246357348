import { useTranslation } from 'react-i18next';
import { Tabs } from '@platform-storybook/circlestorybook';
import { TabsType } from '../../../models/navigation';
import styles from './catalog-page.module.scss';

const CatalogPage = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['common']);
  const tabs: TabsType[] = [
    {
      label: t('tabs.products', { ns: 'catalog' }),
      to: '/catalog/products',
      disabled: false
    },
    {
      label: t('tabs.components'),
      disabled: false,
      to: '/catalog/components'
    }
  ];

  return (
    <section>
      <Tabs tabs={tabs} id={'products'} className={styles['catalog-page__tab']}></Tabs>
      <div>{children}</div>
    </section>
  );
};

export default CatalogPage;
