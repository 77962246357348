import { useEffect, useState } from 'react';
import '@inovua/reactdatagrid-enterprise/index.css';
import {
  Avatar,
  BodyContainer,
  Chips,
  DropdownMenu,
  Header,
  IconButton,
  Toast
} from '@platform-storybook/circlestorybook';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { authActions } from '../../store/auth/auth.reducers';
import { useTranslation } from 'react-i18next';
import './private-app.scss';
import { userInfoSelector } from '../../store/auth/auth.selectors';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { resetAll } from '../../store';
import { ColorPropsEnum } from '../../enum/color.enum';
import { api } from '../../services/api';
import { viteMode } from '../../utils/utils.tsx';
import { useLazyGetConnectedUserQuery } from '../../services/users-rtkq.services.tsx';

const PrivateApp = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const connectedUser = useAppSelector(userInfoSelector);
  const toast = useAppSelector(toastSelector);
  const envLabel = viteMode();
  const [isResetStore, setIsResetStore] = useState<boolean>(false);
  const [getConnectedUser] = useLazyGetConnectedUserQuery();

  useEffect(() => {
    dispatch(resetAll());
    setIsResetStore(true);
    dispatch(api.util.resetApiState());
    getConnectedUser();
  }, []);

  //useGetConnectedUserQuery();

  const getAvatarLetter = () => {
    return connectedUser?.email?.substring(0, 1).toUpperCase();
  };

  const contentAvatarMenu = [
    [
      { label: t('link.myProfile'), type: 'nav', link: '/profile' },
      { label: t('link.myLaboratory'), type: 'nav', link: '/preferences', disabled: true }
    ],
    [
      {
        label: t('link.logout'),
        type: 'button',
        onClick: () => {
          dispatch(authActions.logout());
        }
      }
    ]
  ];

  const contentCandyMenu = [
    [
      {
        label: t('appMenu.circleAppDentist'),
        type: 'nav',
        link: 'https://alpha.circle.dental',
        target: '_blank'
      },
      {
        label: t('appMenu.circleAppLab'),
        type: 'nav',
        link: 'https://dev.lab.circle.dental',
        target: '_blank'
      }
    ]
  ];

  const contentMainMenu = [
    [
      {
        label: t('link.dashboard'),
        type: 'nav',
        link: '/dashboard',
        iconClass: 'fa-gauge'
      },
      {
        label: t('link.orders'),
        type: 'nav',
        link: '/orders',
        iconClass: 'table-list'
      },
      {
        label: t('link.users'),
        type: 'nav',
        link: '/users',
        iconClass: 'fa-users'
      },
      {
        label: t('link.catalog'),
        type: 'nav',
        link: 'catalog/products',
        iconClass: 'fa-book'
      }
    ]
  ];

  return (
    isResetStore && (
      <>
        {toast && (
          <Toast
            message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
            onClose={() => {
              dispatch(feedbackActions.resetToast());
            }}
            autoHideDuration={toast.type === 'danger' ? 8000 : 3000}
            type={toast.type}
            data-cy="global-feedback"
          />
        )}
        <Header logoClass="logo--circle" title={`Circle Admin`}>
          <>
            <div className="private-app__header__title__env">
              {envLabel && <Chips firstLabel={envLabel} color={ColorPropsEnum.WHITE} />}
            </div>
            <nav className="private-app__header__nav">
              <DropdownMenu
                renderTargetButton={(props: { active: boolean }) => (
                  <IconButton
                    className="private-app__header__nav__icon-btn"
                    faIconClass="ellipsis-vertical"
                    mode="candy-menu"
                    isActive={props.active}
                    radius="full"
                    color={ColorPropsEnum.WHITE}
                  />
                )}
                data={contentCandyMenu}
              />
              <DropdownMenu
                renderTargetButton={(props: { active: boolean }) => (
                  <Avatar
                    label={getAvatarLetter() || ''}
                    isActive={props.active}
                    cursorPointer={true}
                    className="private-app__header__nav__avatar"
                  />
                )}
                data={contentAvatarMenu}
              />
            </nav>
          </>
        </Header>
        <BodyContainer
          menuData={contentMainMenu}
          isMenuOpenedInitially={true}
          className="private-app__body">
          <Outlet />
        </BodyContainer>
      </>
    )
  );
};
export default PrivateApp;
