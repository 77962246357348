import React, { useEffect, useState } from 'react';
import styles from './establishment-form.module.scss';
import stylesParent from '../../create-user-page.module.scss';
import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Fieldset,
  SideBarModal,
  Text
} from '@platform-storybook/circlestorybook';
import i18next from 'i18next';
import { Establishment, UserInfo } from '../../../../../../models/user';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  isLoadingCreationUserSelector,
  userSelector
} from '../../../../../../store/users/users.selectors';
import EstablishmentCreationForm from './establishment-creation-form/EstablishmentCreationForm';
import { usersActions } from '../../../../../../store/users/users.reducers';
import { AdminRole, Country, DesignerRole } from '../../../../../../enum/user';
import {
  clinicListSelector,
  designCenterListSelector,
  isClinicsLoadingSelector,
  isDesignCentersLoadingSelector,
  isLaboratoriesLoadingSelector,
  laboratoryListSelector
} from '../../../../../../store/establishment/establishment.selectors';
import { EstablishmentType, TEMP_NEW_ESTABLISHMENT_ID } from '../../../../../../enum/establishment';
import { establishmentActions } from '../../../../../../store/establishment/establishment.reducer';
import { createEstablishment } from '../../../../../../services/establishments.services';
import { isDentistRole, isDesignerRole } from '../../../../../../utils/utils';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { Address } from '../../../../../../models/establishment';

type Props = {
  submitCallback: (userToCreate: UserInfo) => void;
  previousCallback: () => void;
};

const EstablishmentForm = ({ submitCallback, previousCallback }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const clinicList = useAppSelector(clinicListSelector);
  const designCenterList = useAppSelector(designCenterListSelector);
  const laboratoryList = useAppSelector(laboratoryListSelector);
  const isLaboratoriesLoading = useAppSelector(isLaboratoriesLoadingSelector);
  const isClinicsLoadingLoading = useAppSelector(isClinicsLoadingSelector);
  const isDesignCentersLoading = useAppSelector(isDesignCentersLoadingSelector);
  const isLoadingCreationUser = useAppSelector(isLoadingCreationUserSelector);

  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [additionalAddress, setAdditionalAddress] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<Country | undefined>(undefined);
  const [region, setRegion] = useState<string>('');
  const [selectedClinicId, setSelectedClinicId] = useState<number | undefined>(undefined);
  const [selectedDesignCenterId, setSelectedDesignCenterId] = useState<number | undefined>(
    undefined
  );
  const [selectedLaboratoryId, setSelectedLaboratoryId] = useState<number | undefined>(undefined);
  const [isAllowedToOrder, setIsAllowedToOrder] = useState(false);

  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [isShowClinicMandatoryMessage, setIsShowClinicMandatoryMessage] = useState(false);
  const [isShowDesignCenterMandatoryMessage, setIsShowDesignCenterMandatoryMessage] =
    useState(false);
  const [isShowLaboratoryMandatoryMessage, setIsShowLaboratoryMandatoryMessage] = useState<
    boolean | undefined
  >(false);
  const [isCreating, setIsIsCreating] = useState<boolean>(false);

  const clinicMandatory = i18next.t('userForm.clinicMandatory', { ns: 'user' });
  const designCenterMandatory = i18next.t('userForm.designCenterMandatory', { ns: 'user' });
  const laboratoryMandatory = i18next.t('userForm.laboratoryMandatory', { ns: 'user' });

  const isDisplayDentistFields = user?.role ? isDentistRole(user.role) : false;
  const isDisplayDesignCenterFields = user?.role ? isDesignerRole(user.role) : false;

  useEffect(() => {
    if (isDisplayDentistFields && user?.clinic?.id) {
      onSelectClinic(user.clinic.id);
    } else if (isDisplayDesignCenterFields && user?.designCenter?.id) {
      onSelectDesignCenter(user.designCenter.id);
    } else if (user?.laboratory?.id) {
      onSelectLaboratory(user.laboratory.id);
    }
  }, []);

  const isLaboratoryMandatory = () => {
    return (
      !selectedLaboratoryId &&
      user?.role !== AdminRole.ADMIN &&
      user?.role !== DesignerRole.DESIGNER
    );
  };

  const isClinicMandatory = () => {
    return !selectedClinicId && isDisplayDentistFields;
  };

  const isDesignCenterMandatory = () => {
    return !selectedDesignCenterId && isDisplayDesignCenterFields;
  };

  const mapForDropdown = (establishments: Establishment[]) => {
    if (establishments && establishments.length > 0) {
      return establishments.map((ets) => {
        return { label: ets.name, value: ets.id };
      });
    }

    return [];
  };

  const onSelectDesignCenter = (id: number) => {
    setSelectedDesignCenterId(id ?? null);
    updateEstablishment(designCenterList.find((ets) => ets.id === id));
    setIsShowDesignCenterMandatoryMessage(!id);
  };

  const onSelectClinic = (id: number) => {
    setSelectedClinicId(id ?? null);
    updateEstablishment(clinicList.find((ets) => ets.id === id));
    setIsShowClinicMandatoryMessage(!id);
  };

  const onSelectLaboratory = (id: number) => {
    setSelectedLaboratoryId(id ?? null);
    if (!isDisplayDentistFields && !isDisplayDesignCenterFields) {
      updateEstablishment(laboratoryList.find((ets) => ets.id === id));
    }

    setIsShowLaboratoryMandatoryMessage(!id);
  };

  const updateEstablishment = (establishment: Establishment | undefined) => {
    setName(establishment?.name || '');
    setAddress(establishment?.address?.address || '');
    setAdditionalAddress(establishment?.address?.additionalAddress || '');
    setZipCode(establishment?.address?.zipCode || '');
    setCity(establishment?.address?.city || '');
    setRegion(establishment?.address?.region || '');
    setCountry(establishment?.address?.country || undefined);
  };

  const establishmentId = () => {
    // if id is newEstablishment -> return undefined
    return isDisplayDentistFields && selectedClinicId !== undefined
      ? selectedClinicId
      : isDisplayDesignCenterFields
        ? selectedDesignCenterId
        : undefined;
  };

  const saveInfo = (establishmentId: number): UserInfo => {
    const establishment = establishmentId
      ? {
          id: establishmentId,
          name: name,
          address: address
            ? {
                address: address ?? undefined,
                additionalAddress: additionalAddress ?? undefined,
                zipCode: zipCode ?? undefined,
                city: city ?? undefined,
                country: country ?? undefined,
                region: region ?? undefined
              }
            : undefined
        }
      : undefined;
    const userData: UserInfo = {
      ...user,
      isAllowedToOrder: user?.role && isDentistRole(user.role) ? isAllowedToOrder : undefined,
      clinic: isDisplayDentistFields ? establishment : undefined,
      designCenter: isDisplayDesignCenterFields ? establishment : undefined,
      laboratory: selectedLaboratoryId ? { id: selectedLaboratoryId } : undefined
    };
    dispatch(usersActions.setUser(userData));
    return userData;
  };

  const addNewEstablishment = async (): Promise<number> => {
    const newEstablishmentAddress = {
      address: address,
      additionalAddress: additionalAddress ?? undefined,
      zipCode: zipCode,
      city: city,
      country: country,
      region: region ?? undefined
    };

    const newEstablishment = {
      name: name,
      type: isDisplayDentistFields ? EstablishmentType.CLINIC : EstablishmentType.DESIGN_CENTER,
      address: isDisplayDentistFields ? newEstablishmentAddress : undefined
    };

    const createdEstablishment = await createEstablishment(newEstablishment);

    if (isDisplayDentistFields) {
      setSelectedClinicId(createdEstablishment.data.id);
      dispatch(establishmentActions.removeNewClinicInList());
      dispatch(establishmentActions.addClinicToList(createdEstablishment.data));
    } else {
      setSelectedDesignCenterId(createdEstablishment.data.id);
      dispatch(establishmentActions.removeNewDesignCenterInList());
      dispatch(establishmentActions.addDesignCenterToList(createdEstablishment.data));
    }
    // We return the created establishment id because the selectedDesignCenterId or selectedClinicId states are not always up to date without refresh
    return createdEstablishment.data.id;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isClinicMandatory() || isDesignCenterMandatory() || isLaboratoryMandatory()) {
      setIsShowLaboratoryMandatoryMessage(isLaboratoryMandatory());
      setIsShowClinicMandatoryMessage(isClinicMandatory);
      setIsShowDesignCenterMandatoryMessage(isDesignCenterMandatory);
      return;
    }
    setIsIsCreating(true);
    let etsId = establishmentId();
    if (etsId === TEMP_NEW_ESTABLISHMENT_ID) {
      etsId = await addNewEstablishment();
    }
    const user = saveInfo(etsId as number);
    setIsIsCreating(false);
    submitCallback(user);
  };

  const handlePreviousButton = () => {
    saveInfo(establishmentId() as number);
    previousCallback();
  };

  const handleCloseSidebar = (establishment: Establishment) => {
    if (isDisplayDentistFields) {
      dispatch(establishmentActions.removeNewClinicInList());
      dispatch(establishmentActions.addClinicToList(establishment));
      setIsShowClinicMandatoryMessage(!establishment.id);
      setSelectedClinicId(establishment.id);
    } else {
      dispatch(establishmentActions.removeNewDesignCenterInList());
      dispatch(establishmentActions.addDesignCenterToList(establishment));
      setIsShowDesignCenterMandatoryMessage(!establishment.id);
      setSelectedDesignCenterId(establishment.id);
    }
    updateEstablishment(establishment);
    setSideBarOpened(false);
  };

  const isNewEstablishmentToCreate = (): boolean =>
    selectedClinicId === TEMP_NEW_ESTABLISHMENT_ID ||
    selectedDesignCenterId === TEMP_NEW_ESTABLISHMENT_ID;

  const creationTitle = (): string => {
    if (isDisplayDentistFields) {
      if (isNewEstablishmentToCreate()) {
        return i18next.t('establishmentModal.updateClinic', { ns: 'user' });
      } else {
        return i18next.t('establishmentModal.createClinic', { ns: 'user' });
      }
    } else if (isDisplayDesignCenterFields) {
      if (isNewEstablishmentToCreate()) {
        return i18next.t('establishmentModal.updateDesignCenter', { ns: 'user' });
      } else {
        return i18next.t('establishmentModal.createDesignCenter', { ns: 'user' });
      }
    } else return '';
  };

  const laboratoryLabel = isDisplayDentistFields
    ? i18next.t('userForm.DENTIST_LABORATORY', { ns: 'user' })
    : i18next.t('userForm.LABORATORY', { ns: 'user' });

  const establishmentAddress: Address = {
    address: isNewEstablishmentToCreate() ? address : '',
    additionalAddress: isNewEstablishmentToCreate() ? additionalAddress : '',
    zipCode: isNewEstablishmentToCreate() ? zipCode : '',
    city: isNewEstablishmentToCreate() ? city : '',
    country: isNewEstablishmentToCreate() ? country : undefined,
    region: isNewEstablishmentToCreate() ? region : ''
  };

  const newEstablishment: Establishment = {
    id: TEMP_NEW_ESTABLISHMENT_ID, // It is mandatory to set an id into the establishment to create in order to add it in the dropdown component
    name: isNewEstablishmentToCreate() ? name : '',
    address: isDisplayDentistFields ? establishmentAddress : undefined
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={stylesParent['create-user-form__form']}>
        <Fieldset size="m" className={stylesParent['create-user-form__form__fieldset']}>
          {user?.role !== AdminRole.ADMIN ? (
            <>
              {!isDisplayDesignCenterFields && (
                <>
                  <Dropdown
                    label={laboratoryLabel}
                    data={isLaboratoriesLoading ? [] : [mapForDropdown(laboratoryList)]}
                    value={selectedLaboratoryId}
                    placeholder={i18next.t('userForm.choose', { ns: 'user' })}
                    onChange={(newValue: number) => onSelectLaboratory(+newValue)}
                    helperText={isShowLaboratoryMandatoryMessage ? laboratoryMandatory : undefined}
                    variant={
                      isShowLaboratoryMandatoryMessage
                        ? ColorPropsEnum.DANGER
                        : ColorPropsEnum.DEFAULT
                    }
                    className={stylesParent['create-user-form__form__input']}
                  />
                  {user?.role && isDentistRole(user.role) && (
                    <Checkbox
                      label={i18next.t('userForm.userAllowedToOrder', { ns: 'user' })}
                      isChecked={isAllowedToOrder}
                      onClick={() => {
                        setIsAllowedToOrder(!isAllowedToOrder);
                      }}
                      className={styles['establishment-form__checkbox']}
                    />
                  )}
                </>
              )}
              {isDisplayDesignCenterFields && (
                <Dropdown
                  label={i18next.t('userForm.DESIGN_CENTER', { ns: 'user' })}
                  data={isDesignCentersLoading ? [] : [mapForDropdown(designCenterList)]}
                  value={selectedDesignCenterId}
                  placeholder={i18next.t('userForm.choose', { ns: 'user' })}
                  onChange={(newValue: number) => onSelectDesignCenter(+newValue)}
                  helperText={
                    isShowDesignCenterMandatoryMessage ? designCenterMandatory : undefined
                  }
                  variant={
                    isShowDesignCenterMandatoryMessage
                      ? ColorPropsEnum.DANGER
                      : ColorPropsEnum.DEFAULT
                  }
                  className={styles['establishment-form__dropdown-address']}
                />
              )}
              {isDisplayDentistFields && (
                <Dropdown
                  label={i18next.t('userForm.CLINIC', { ns: 'user' })}
                  data={isClinicsLoadingLoading ? [] : [mapForDropdown(clinicList)]}
                  value={selectedClinicId}
                  placeholder={i18next.t('userForm.choose', { ns: 'user' })}
                  onChange={(newValue: number) => onSelectClinic(+newValue)}
                  helperText={isShowClinicMandatoryMessage ? clinicMandatory : undefined}
                  variant={
                    isShowClinicMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
                  }
                  className={styles['establishment-form__dropdown-address']}
                />
              )}
              {(isDisplayDentistFields || isDisplayDesignCenterFields) && (
                <>
                  <Text
                    label={i18next.t('establishmentModal.or', { ns: 'user' })}
                    className={styles['establishment-form__text']}
                  />
                  <Button
                    className={styles['establishment-form__button']}
                    category="secondary"
                    iconRight="plus"
                    label={creationTitle()}
                    onClick={() => setSideBarOpened(true)}></Button>
                </>
              )}

              {name && (
                <Box className={styles['establishment-form__address']}>
                  <>
                    <Text label={name} />
                    <Text label={address} />
                    <Text label={additionalAddress} />
                    {(zipCode || city) && <Text label={zipCode + ' ' + city} />}
                    {(country || region) && (
                      <Text
                        label={
                          (country && Object.values(Country).includes(country)
                            ? i18next.t(`countries.${country.toLowerCase()}`, {
                                ns: 'common'
                              })
                            : '') +
                          ' ' +
                          region
                        }
                      />
                    )}
                  </>
                </Box>
              )}
            </>
          ) : (
            <Text label={i18next.t('establishmentModal.theEnd', { ns: 'user' })} />
          )}
        </Fieldset>
        <div className="form__buttons">
          <Button
            label={i18next.t('action.previous', { ns: 'common' })}
            category="secondary"
            onClick={handlePreviousButton}
            iconLeft="fa-chevron-left"
          />
          <Button
            label={i18next.t('establishmentModal.createUser', { ns: 'user' })}
            type="submit"
            isLoading={isLoadingCreationUser || isCreating}
          />
        </div>
      </form>
      <SideBarModal
        title={creationTitle()}
        isOpened={sideBarOpened}
        closeOnOutsideClick={true}
        onClose={() => setSideBarOpened(false)}>
        <EstablishmentCreationForm
          establishment={newEstablishment}
          setSideBarOpenedCallback={handleCloseSidebar}
          isNewEstablishmentCreated={isNewEstablishmentToCreate()}
          isWithAddress={isDisplayDentistFields}
        />
      </SideBarModal>
    </>
  );
};

export default EstablishmentForm;
