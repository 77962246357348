import { api } from './api';

export const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteOrder: builder.mutation<void, { orderNumber: string }>({
      query: ({ orderNumber }) => ({
        url: `/orders/${orderNumber}`,
        method: 'DELETE'
      })
    })
  })
});

export const { useDeleteOrderMutation } = ordersApi;
