import { useCallback, useEffect, useState } from 'react';
import styles from './orders-page.module.scss';
import { Button, SideBarModal, Tabs } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TabsType } from '../../../models/navigation';
import { useTranslation } from 'react-i18next';
import { useLazyGetOrdersQuery } from '../../../services/orders.services';
import DatagridFeature from '../../../features/datagrid/DatagridFeature';
import {
  FilterReducer,
  LoadDataProps,
  ServicePaginatedResponse,
  TypeColWithNamePropertyPlatform
} from '../../../models/datagrid';
import { colActions, getColumnSettings, getFilters, getSort, rowClassname } from './orders';
import { datagridSettingsActions } from '../../../store/datagrids-settings/datagrids-settings.reducers';
import { ordersDatagridStateSelector } from '../../../store/datagrids-settings/datagrids-settings.selectors';
import DeleteOrderDialog from './delete-order-dialog/DeleteOrderDialog.tsx';

import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import ExportManufacturedOrdersForm from './export-orders-form/ExportManufacturedOrdersForm';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { useGetCommonTypesQuery } from '../../../services/common-types-rtkq.services';
import { isConnectedUserAdminSelector } from '../../../store/users/users.selectors.tsx';
import DatagridReload from '../../../features/datagrid/DatagridReload.tsx';
import { Order } from '../../../models/order.tsx';
const OrdersPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'orders']);
  const datagridSettings = useAppSelector(ordersDatagridStateSelector);
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const isConnectedUserAdmin = useAppSelector(isConnectedUserAdminSelector);
  const [getOrders] = useLazyGetOrdersQuery();

  const [sideBarExportOpened, setSideBarExportOpened] = useState(false);
  const [reloadDate, setReloadDate] = useState<Date>();
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState<boolean>(false);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  const loadData = ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = getFilters(filterValue);
    const sort = getSort(sortInfo);

    return getOrders({ page, limit, filters, sort })
      .unwrap()
      .then((orders: ServicePaginatedResponse) => {
        setReloadDate(new Date());
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const dataSource = useCallback(loadData, [isReloadNeeded]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'orders',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    let newSort = newSortInfo as TypeSingleSortInfo;
    // For now, we reset filters at every changes
    if (Array.isArray(newSort)) {
      newSort = newSort[newSort.length - 1];
    }
    dispatch(datagridSettingsActions.setDatagridSort({ datagrid: 'orders', sort: newSort }));
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetOrdersDatagridFilters());
  };

  const tabs: TabsType[] = [
    {
      label: t('tabs.orders', { ns: 'orders' }),
      disabled: false,
      to: '/orders'
    }
  ];

  const handleDelete = (order: Order) => {
    setSelectedOrder(order);
    setIsDeleteDialogOpened(true);
  };

  return (
    <>
      <div className={styles['orders-page__tab']}>
        <Tabs tabs={tabs} id={'dashboard'}></Tabs>
      </div>
      <div className={styles['orders-page__actions']} id={'actions-buttons'}>
        <Button
          data-dy="remove-filter-button"
          category="tertiary"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={removeFilters}
        />
        {isConnectedUserAdmin && (
          <Button
            data-cy="export-orders-button"
            className={styles['orders-page__actions__export']}
            category="secondary"
            iconLeft="fa-file-excel"
            id={'export-manufactured-orders'}
            label={t('datagrid.export.title', { ns: 'orders' })}
            onClick={() => setSideBarExportOpened(true)}
          />
        )}
        {reloadDate && (
          <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
        )}
      </div>
      <div className={styles['orders-page__content']}>
        {!areCommonTypesLoading && (
          <DatagridFeature
            key="orders"
            style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
            columns={
              [
                ...getColumnSettings(commonTypes, dispatch),
                ...colActions(handleDelete)
              ] as TypeColWithNamePropertyPlatform[]
            }
            filterValue={datagridSettings.filters}
            onFilterValueChange={onFilterValueChange}
            sortInfo={datagridSettings.sort}
            onSortInfoChange={onSortInfoChange}
            dataSource={dataSource}
            rowClassName={rowClassname}
            rowHeight={46}
          />
        )}
      </div>
      {sideBarExportOpened && (
        <SideBarModal
          title={t('datagrid.export.title', {
            ns: 'orders'
          })}
          isOpened={sideBarExportOpened}
          closeOnOutsideClick={true}
          onClose={() => setSideBarExportOpened(false)}>
          <ExportManufacturedOrdersForm
            onSubmitCallback={(keepOpened: boolean) =>
              setSideBarExportOpened(keepOpened)
            }></ExportManufacturedOrdersForm>
        </SideBarModal>
      )}

      {isDeleteDialogOpened && (
        <DeleteOrderDialog
          title={t(`dialog.deleteOrder.text`, {
            ns: 'orders',
            orderNumber: (selectedOrder?.orderNumber as string).slice(-3)
          })}
          orderNumber={selectedOrder?.orderNumber}
          cancelButtonLabel={t('action.cancel', { ns: 'common' })}
          confirmButtonLabel={t(`action.delete`, {
            ns: 'common'
          })}
          isOpened={isDeleteDialogOpened}
          onCancel={() => setIsDeleteDialogOpened(false)}
          onConfirmSuccess={() => {
            setIsDeleteDialogOpened(false);
            setIsReloadNeeded(true);
          }}
        />
      )}
    </>
  );
};

export default OrdersPage;
