import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo, UsersReducer } from '../../models/user';

const initialUsers: UsersReducer = {
  user: undefined,
  isUserLoading: true,
  tags: {},
  isLoadingTags: false,
  isLoadingCreationUser: false,
  isLoadingPatchUser: false
};

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsers,
  reducers: {
    setUser: (state, action: PayloadAction<UserInfo | undefined>) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<UsersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialUsers };
    });
  }
});

const usersActions = usersSlice.actions;

export { usersSlice, usersActions };
