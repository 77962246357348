import { useTranslation } from 'react-i18next';
import useForm from '../../../../../../utils/useForm';
import styles from './edit-product-forms.module.scss';
import { Button, Checkbox, Box } from '@platform-storybook/circlestorybook';
import { Product, ProductRule, ProductUpdateForm } from '../../../../../../models/product';
import { CheckboxStringItem } from '../../../../../../models/form';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { usePatchProductMutation } from '../../../../../../services/products-rtkq.services';

type Props = {
  productToEdit: Product;
  onSubmitCallback?: () => void;
};

const ProductRuleForm = ({ productToEdit, onSubmitCallback }: Props) => {
  const { t } = useTranslation(['catalog']);
  const productRule = productToEdit?.productRule;

  const [updateProduct, { isLoading: isPatchLoading }] = usePatchProductMutation();

  // Form configuration
  const onSubmit = async () => {
    const newProductRule: ProductRule = {
      allowSameProductInOrder: allowSameProductInOrderProp[0].isChecked,
      allowSameProductOnArch: allowSameProductOnArchProp[0].isChecked,
      allowSaneTeethInArch: !removeSaneTeethInArchProp[0].isChecked
    };

    await updateProduct({
      id: productToEdit.id?.toString(),
      productRule: newProductRule
    } as ProductUpdateForm);

    if (onSubmitCallback) onSubmitCallback();
  };
  const customHandleCheck = (name: string) => {
    const currentProp = values[name] as CheckboxStringItem[];
    const allowSameProductOnArchProp = values.allowSameProductOnArch as CheckboxStringItem[];

    // If there can't be twice the same product in order, there can't be twice the same product on arch
    if (currentProp[0].isChecked) {
      if (name === 'allowSameProductInOrder') {
        allowSameProductOnArchProp[0].isChecked = false;
      }
    }
    // Call default form handleCheck to update values
    handleCheck(name, name);
  };

  const { values, handleSubmit, handleCheck } = useForm(
    {
      removeSaneTeethInArch: [
        {
          value: 'removeSaneTeethInArch',
          isChecked: productRule ? !productRule.allowSaneTeethInArch : false
        }
      ],
      allowSameProductInOrder: [
        {
          value: 'allowSameProductInOrder',
          isChecked: productRule ? productRule.allowSameProductInOrder : false
        }
      ],
      allowSameProductOnArch: [
        {
          value: 'allowSameProductOnArch',
          isChecked: productRule ? productRule.allowSameProductOnArch : false
        }
      ]
    },
    onSubmit
  );

  const allowSameProductInOrderProp = values.allowSameProductInOrder as CheckboxStringItem[];
  const allowSameProductOnArchProp = values.allowSameProductOnArch as CheckboxStringItem[];
  const removeSaneTeethInArchProp = values.removeSaneTeethInArch as CheckboxStringItem[];

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit}>
        <Checkbox
          label={t('products.productForm.allowSameProductInOrder.label')}
          helperText={t('products.productForm.allowSameProductInOrder.helper')}
          isChecked={allowSameProductInOrderProp[0].isChecked}
          onClick={() => customHandleCheck('allowSameProductInOrder')}
          className={styles['edit-product-forms__checkbox']}
          data-cy="product-rule__checkbox-allow-same-product-in-order"
        />
        <Checkbox
          label={t('products.productForm.allowSameProductOnArch.label')}
          helperText={t('products.productForm.allowSameProductOnArch.helper')}
          isDisabled={!allowSameProductInOrderProp[0].isChecked}
          isChecked={allowSameProductOnArchProp[0].isChecked}
          onClick={() => customHandleCheck('allowSameProductOnArch')}
          className={[
            styles['edit-product-forms__checkbox'],
            styles['edit-product-forms__checkbox--level-2']
          ].join(' ')}
          data-cy="product-rule__checkbox-allow-same-product-on-arch"
        />
        <div className={styles['edit-product-forms__checkbox']}>
          <Checkbox
            label={t('products.productForm.removeSaneTeethInArch.label')}
            helperText={t('products.productForm.removeSaneTeethInArch.helper')}
            isChecked={removeSaneTeethInArchProp[0].isChecked}
            onClick={() => customHandleCheck('removeSaneTeethInArch')}
            data-cy="product-rule__checkbox--remove-sane-teeth-in-arch"
          />
        </div>
        <div className={styles['edit-product-forms__form-button']}>
          <Button
            label={t('action.update', { ns: 'common' })}
            type="submit"
            isLoading={isPatchLoading}
            data-cy="productRule__submit"
          />
        </div>
      </form>
    </Box>
  );
};

export default ProductRuleForm;
