import i18next from 'i18next';
import { AdminRole, DentistRole, DesignerRole, LabUserRole } from '../../../../../../enum/user.ts';

/**
 * Returns grouped array of user roles available for creation
 * @param isCurrentUserAdmin
 */
export const getAvailableRolesForCurrentUser = (isCurrentUserAdmin: boolean) => {
  const commonRoles = [
    [
      {
        label: i18next.t(`roles.${DentistRole.ASSISTANT}`, { ns: 'user' }),
        value: DentistRole.ASSISTANT
      },
      {
        label: i18next.t(`roles.${DentistRole.DENTIST}`, { ns: 'user' }),
        value: DentistRole.DENTIST
      }
    ],
    [
      {
        label: i18next.t(`roles.${LabUserRole.PROSTHETIST}`, { ns: 'user' }),
        value: LabUserRole.PROSTHETIST
      },
      {
        label: i18next.t(`roles.${LabUserRole.SUPERVISOR}`, { ns: 'user' }),
        value: LabUserRole.SUPERVISOR
      },
      {
        label: i18next.t(`roles.${LabUserRole.SUPPORT}`, { ns: 'user' }),
        value: LabUserRole.SUPPORT
      },
      {
        label: i18next.t(`roles.${LabUserRole.TECHNICIAN}`, { ns: 'user' }),
        value: LabUserRole.TECHNICIAN
      },
      {
        label: i18next.t(`roles.${LabUserRole.LAB_DESIGNER}`, { ns: 'user' }),
        value: LabUserRole.LAB_DESIGNER
      }
    ],
    [
      {
        label: i18next.t(`roles.${DesignerRole.DESIGNER}`, { ns: 'user' }),
        value: DesignerRole.DESIGNER
      }
    ]
  ];

  return isCurrentUserAdmin
    ? [
        ...commonRoles,
        ...[
          [
            {
              label: i18next.t(`roles.${AdminRole.ADMIN}`, { ns: 'user' }),
              value: AdminRole.ADMIN
            }
          ]
        ]
      ]
    : [...commonRoles];
};
