import axios, { AxiosResponse } from 'axios';

export const getUsers = (
  page: number,
  limit: number,
  filters: string,
  sort: string
): Promise<AxiosResponse> => {
  return axios.get(`/users?page=${page}&limit=${limit}${filters}${sort}`);
};
