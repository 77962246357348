import { Box, Button, RadioList, TextField } from '@platform-storybook/circlestorybook';
import i18next from 'i18next';
import { DentalNotation, DentistRole, Language } from '../../../../../enum/user';
import { UserInfo } from '../../../../../models/user';
import styles from './user-forms.module.scss';
import { dentalNotations, isDentistRole, languages } from '../../../../../utils/utils';
import useForm from '../../../../../utils/useForm';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { useGetUserQuery } from '../../../../../services/users-rtkq.services';
import { StringObject } from '../../../../../models/common';

type Props = {
  email: string;
  saveCallback: (user: Partial<UserInfo>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const CustomizationForm = ({ email, saveCallback, closeSideNavCallback }: Props) => {
  // this will not refetch, it just reselects
  const { data: user } = useGetUserQuery(email);
  const isDentistOrAssistantRole = isDentistRole(user?.role as DentistRole);
  const deliveryDelayMandatory = i18next.t('userForm.deliveryDelayMandatory', { ns: 'user' });

  const saveCustomizationInfo = () => {
    if (user?.email) {
      const deliveryDelay = isDentistOrAssistantRole
        ? +(values.deliveryDelay as number)
        : undefined;
      saveCallback(
        {
          ...values,
          deliveryDelay
        },
        user?.email
      );
    }
    closeSideNavCallback(false);
  };

  const onSubmit = () => {
    saveCustomizationInfo();
  };

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (
        (!values[key] && key !== 'deliveryDelay') ||
        (key === 'deliveryDelay' && isDentistOrAssistantRole && values[key] === undefined)
      ) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, handleSubmit, handleSelect, handleChange, handleBlur } = useForm(
    {
      dentalNotation: user?.dentalNotation ?? DentalNotation.ISO,
      language: user?.language ?? Language.fr_FR,
      deliveryDelay:
        user?.deliveryDelay || user?.deliveryDelay === 0 ? +user.deliveryDelay : undefined
    },
    onSubmit,
    validateAllFieldsRequired
  );

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit}>
        <RadioList
          title={i18next.t('userForm.dentalNotation', { ns: 'user' })}
          options={dentalNotations}
          name="dentalNotation"
          selectedValue={values.dentalNotation}
          onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
          className={styles['user-forms__input']}
          data-cy="user-forms-dental-notation"
        />
        <RadioList
          title={i18next.t('userForm.language', { ns: 'user' })}
          options={languages}
          name="language"
          selectedValue={values.language}
          onClick={(newValue: Language) => handleSelect(newValue, 'language')}
          className={styles['user-forms__input']}
          data-cy="user-forms-language"
        />
        {isDentistOrAssistantRole && (
          <TextField
            label={i18next.t('userForm.deliveryDelay', { ns: 'user' })}
            type="number"
            id="deliveryDelay"
            name="deliveryDelay"
            data-cy="user-forms-delivery-delay"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.deliveryDelay ? deliveryDelayMandatory : undefined}
            variant={errors.deliveryDelay ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-user-form__form__input']}
            placeholder="2"
            min="0"
            value={values.deliveryDelay}
          />
        )}
        <div className="form__submit-button">
          <Button
            label={i18next.t('action.update', { ns: 'common' })}
            data-cy="user-forms-customization-submit"
            type="submit"
          />
        </div>
      </form>
    </Box>
  );
};
export default CustomizationForm;
