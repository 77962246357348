import { useTranslation } from 'react-i18next';
import styles from '../create-component-page.module.scss';

import {
  Button,
  CheckboxList,
  Dropdown,
  Fieldset,
  Text
} from '@platform-storybook/circlestorybook';

import useForm from '../../../../../../utils/useForm';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  allowedAngulationsCheckboxSelector,
  allowedBrandsDropdownSelector,
  allowedToothStratificationTechniquesCheckboxSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { componentsActions } from '../../../../../../store/components/components.reducers';
import { componentSelector } from '../../../../../../store/components/components.selectors';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const OptionsForm = ({ nextCallback, previousCallback }: Props) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const component = useAppSelector(componentSelector);
  const allowedBrandsDropdown = useAppSelector(allowedBrandsDropdownSelector);
  const allowedAngulationsCheckbox = useAppSelector(allowedAngulationsCheckboxSelector);
  const allowedToothStratificationTechniques = useAppSelector(
    allowedToothStratificationTechniquesCheckboxSelector
  );

  const onSubmit = () => {
    saveComponentInfo();
    nextCallback();
  };

  const { values, handleCheck, handleMultiSelect, handleSubmit } = useForm(
    {
      brands: component?.brands ?? allowedBrandsDropdown,
      angulations: component?.angulations ?? allowedAngulationsCheckbox,
      toothStratificationTechniques:
        component?.toothStratificationTechniques ?? allowedToothStratificationTechniques
    },
    onSubmit
  );

  const saveComponentInfo = () => {
    dispatch(componentsActions.setComponent({ ...component, ...values }));
  };

  const handlePreviousButton = () => {
    saveComponentInfo();
    previousCallback();
  };

  const noChoiceAvailable =
    allowedBrandsDropdown.length === 0 &&
    allowedAngulationsCheckbox?.length === 0 &&
    allowedToothStratificationTechniques?.length === 0;
  return (
    <form onSubmit={handleSubmit} className={styles['create-component-page__form']}>
      <Fieldset size="m" className={styles['create-component-page__form__fieldset']}>
        {noChoiceAvailable ? (
          <Text label={t('components.componentForm.noChoice')}></Text>
        ) : (
          <>
            {allowedBrandsDropdown?.length > 0 && (
              <Dropdown
                data-cy="component-brands"
                multichoice={true}
                label={t('components.componentForm.brandInput.label')}
                name="brands"
                placeholder={t('components.componentForm.choose')}
                data={values?.brands}
                onChange={(selectedValue: number) => handleMultiSelect(selectedValue, 'brands')}
                className={styles['create-component-page__form__fieldset__input']}
              />
            )}
            {allowedAngulationsCheckbox?.length > 0 && (
              <CheckboxList
                data-cy="component-angulations"
                name="angulations"
                title={t('components.componentForm.angulationInput.label')}
                data={values?.angulations}
                onClick={handleCheck}
                className={styles['create-component-page__form__fieldset__input']}
              />
            )}
            {allowedToothStratificationTechniques?.length > 0 && (
              <CheckboxList
                data-cy="component-stratifications"
                name="toothStratificationTechniques"
                title={t('components.componentForm.toothStratificationTechniquesInput.label')}
                data={values?.toothStratificationTechniques}
                onClick={handleCheck}
                className={styles['create-component-page__form__fieldset__input']}
              />
            )}
          </>
        )}
      </Fieldset>

      <div className="form__buttons">
        <Button
          label={t('action.previous', { ns: 'common' })}
          category="secondary"
          onClick={handlePreviousButton}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default OptionsForm;
