import { Dispatch, SetStateAction } from 'react';
import { IconButton, Text, Tooltip, TooltipContent } from '@platform-storybook/circlestorybook';
import { formatDate } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

type props = {
  reloadDate: Date;
  setIsReloadNeeded: Dispatch<SetStateAction<boolean>>;
};

const DatagridReload = ({ reloadDate, setIsReloadNeeded }: props) => {
  const { t } = useTranslation(['orders']);

  const reload = () => {
    setIsReloadNeeded(true);
  };

  return (
    <div>
      {reloadDate && (
        <Tooltip>
          <Text
            data-cy="datagrid-reload-date"
            size="s"
            label={formatDate(reloadDate, {
              hour: 'numeric',
              minute: 'numeric',
              year: undefined,
              month: undefined,
              day: undefined
            })}
          />
          <TooltipContent>{formatDate(reloadDate)}</TooltipContent>
        </Tooltip>
      )}
      <Tooltip>
        <IconButton
          faIconClass="fa-refresh"
          radius="full"
          onClick={reload}
          data-cy="datagrid-reload-date-button"
        />
        <TooltipContent>{t('datagrid.action.reload')}</TooltipContent>
      </Tooltip>
    </div>
  );
};

export default DatagridReload;
