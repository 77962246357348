// This is temporary
// To Do: add a date format parameter (short / medium / long )
// handle locale
// separator should not be in parameters
// Use moment library

import i18next from '../i18n.tsx';
import { ErrorCode, ToastType } from '../enum/feedback';
import {
  AdminRole,
  AllRoles,
  DentalNotation,
  DentistRole,
  DesignerRole,
  LabUserRole,
  Language
} from '../enum/user';
import { EstablishmentType } from '../enum/establishment';
import { HttpStatusCode } from 'axios';
import { CircleSoftwareCompatibilityEnum } from '../enum/product.enum';
import { Product, ProductCreationForm } from '../models/product';
import { RadioItem } from '../models/form';
import { forceRefreshToken } from '../services/firebase.services.tsx';
import { Dispatch } from 'react';
import { feedbackActions } from '../store/feedback/feedback.reducer.tsx';
import { WorkflowPendingStepEnum, WorkflowStepEnum } from '../enum/workflow-step.ts';
import { authActions } from '../store/auth/auth.reducers.tsx';

export const formatDate = (date: Date, options?: Intl.DateTimeFormatOptions) => {
  options = options
    ? { ...options, hour12: false }
    : {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      };
  return date.toLocaleString(i18next.language, options);
};

/* We specify this rule because we don't know the payload type, and we use explicitly error as object after.*/
/* So this is an emergency case, don't reproduce */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getMessageError = (error: any): string => {
  let message = i18next.t('generic', { ns: 'error' });

  // switch between axios & rtk.
  const errorDetails = 'response' in error ? error.response : error;

  if ('data' in errorDetails) {
    if (typeof errorDetails.data === 'object') {
      // Handle error from our backend
      if (Object.values(ErrorCode).includes(errorDetails.data.error)) {
        message = i18next.t(errorDetails.data.error, { ns: 'error' });
      } else if (errorDetails.status !== HttpStatusCode.InternalServerError) {
        message = errorDetails.data.message;
      }
    }
  }

  return message;
};

export const capitalize = (string: string) => {
  return string?.toLowerCase().charAt(0).toUpperCase() + string?.toLowerCase().slice(1);
};

export const isDentistRole = (userRole: AllRoles): boolean => {
  return Object.keys(DentistRole).includes(userRole);
};

export const isDesignerRole = (userRole: AllRoles): boolean => {
  return Object.keys(DesignerRole).includes(userRole);
};
export const isLaboratory = (establishmentType: EstablishmentType): boolean => {
  return establishmentType === EstablishmentType.LABORATORY;
};

export const isClinic = (establishmentType: string): boolean => {
  return establishmentType === EstablishmentType.CLINIC;
};

export const isDesignCenter = (establishmentType: string): boolean => {
  return establishmentType === EstablishmentType.DESIGN_CENTER;
};

export const dentalNotations = Object.keys(DentalNotation).map((value) => {
  return {
    label: i18next.t(`userForm.${value.toLowerCase()}.label`, { ns: 'user' }),
    value: value,
    helperText: i18next.t(`userForm.${value.toLowerCase()}.helperText`, {
      ns: 'user'
    })
  };
});

export const getCircleSoftwareCompatibility = (
  product: Product | ProductCreationForm | undefined
) => {
  if (product?.isChairSide) {
    return CircleSoftwareCompatibilityEnum.CHAIRSIDE;
  }
  if (product?.isOneDesign) {
    return CircleSoftwareCompatibilityEnum.ONEDESIGN;
  }
  if (product?.circleCadVersion) {
    return CircleSoftwareCompatibilityEnum.CIRCLECAD;
  }
  return CircleSoftwareCompatibilityEnum.NONE;
};

export const circleSoftwareCompatibilitiesRadioItems: RadioItem[] = Object.keys(
  CircleSoftwareCompatibilityEnum
).map((value) => {
  return {
    label: i18next.t(`products.productForm.circleSoftwareCompatibility.${value.toLowerCase()}`, {
      ns: 'catalog'
    }),
    value: value
  };
});

export const languages = Object.keys(Language).map((value) => {
  return { label: i18next.t(`language.${value}`, { ns: 'common' }), value: value };
});

export const viteMode = (): string => {
  let mode = '';
  if (import.meta.env.MODE === 'development' && import.meta.env.VITE_GOOGLE_CLOUD_DEV) {
    mode = 'dev';
  } else if (import.meta.env.MODE === 'development') {
    mode = 'local';
  }
  return mode;
};

export const isOrdersApi = (urlStartPart: string): boolean => {
  return ['orders', 'common-types', 'components', 'products', 'labs'].includes(urlStartPart);
};

export const isUsersApi = (urlStartPart: string): boolean => {
  return ['users', 'establishments'].includes(urlStartPart);
};

export const isManuApi = (urlStartPart: string): boolean => {
  return ['manufacturing'].includes(urlStartPart);
};

export const isInPendingStatus = (
  currentStep?: WorkflowStepEnum | WorkflowPendingStepEnum
): boolean =>
  (currentStep &&
    Object.values(WorkflowPendingStepEnum).includes(
      currentStep as WorkflowPendingStepEnum
    )) as boolean;

export const checkTokenValidity = (dispatch: Dispatch<any>) => {
  /**
   * Check if the current token is currently valid.
   * Otherwise, redirect the user to the login page.
   */
  forceRefreshToken().catch((err) => {
    if (err?.message.includes('auth/user-token-expired')) {
      dispatch(
        feedbackActions.setToast({
          message: i18next.t('auth/user-token-expired', { ns: 'error' }),
          type: ToastType.DANGER
        })
      );
    } else {
      dispatch(feedbackActions.setToast({ message: err.message, type: ToastType.DANGER }));
    }
  });
};
/*
 * Return an object containing the parts of the given orderNumber, before and after the 3 last characters.
 * It is useful to isolate the last part since it is what will mainly be used to identify an order.
 *
 * @param {orderNumber} string - The order number to split.
 * @returns {{ beforeLastPart: string, lastPart: string }} An object containing the parts of the order.
 */
export const splitOrderNumber = (
  orderNumber: string
): { beforeLastPart: string; lastPart: string } => {
  const lastIndex = orderNumber.length - 3;

  if (lastIndex === -1) {
    return {
      beforeLastPart: orderNumber,
      lastPart: ''
    };
  }

  return {
    beforeLastPart: orderNumber.substring(0, lastIndex),
    lastPart: orderNumber.substring(lastIndex)
  };
};

export const getPendingStep = (
  currentStep?: WorkflowStepEnum | WorkflowPendingStepEnum
): WorkflowPendingStepEnum | undefined => {
  if (isInPendingStatus(currentStep)) {
    return currentStep as WorkflowPendingStepEnum;
  }
  const pendingStepKey = ('PENDING_' + currentStep).toUpperCase();
  if (isInPendingStatus(pendingStepKey as WorkflowPendingStepEnum)) {
    return WorkflowPendingStepEnum[pendingStepKey as keyof typeof WorkflowPendingStepEnum];
  }
};

export const saveUserStateForTests = (dispatch: Dispatch<object>) => {
  const isAuthorized = sessionStorage.getItem('isAuthorizedForTests') !== 'false';
  const roleValue = sessionStorage.getItem('roleForTests');
  const role =
    roleValue && Object.values(LabUserRole).includes(roleValue as LabUserRole)
      ? (roleValue as LabUserRole)
      : AdminRole.ADMIN;
  dispatch(
    authActions.saveUserState({
      firebaseUser: '{"email": "dev-platform+cypress@circle.dental", "name": "cypress user"}',
      isAuthorized,
      role
    })
  );
};
