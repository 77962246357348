export enum Country {
  CA = 'CA',
  FR = 'FR',
  DE = 'DE',
  US = 'US'
}

export enum AdminRole {
  ADMIN = 'ADMIN'
}

export enum DentistRole {
  DENTIST = 'DENTIST',
  ASSISTANT = 'ASSISTANT'
}

export enum DesignerRole {
  DESIGNER = 'DESIGNER'
}

export enum LabUserRole {
  LAB_DESIGNER = 'LAB_DESIGNER',
  SUPERVISOR = 'SUPERVISOR',
  PROSTHETIST = 'PROSTHETIST',
  TECHNICIAN = 'TECHNICIAN',
  SUPPORT = 'SUPPORT'
}

export type AllRoles = DentistRole | AdminRole | LabUserRole | DesignerRole;

export enum CalendarState {
  OPEN = 'OPEN',
  CLOS = 'CLOS'
}

export enum DentalNotation {
  ISO = 'ISO',
  US = 'US'
}

export enum Language {
  fr_FR = 'fr_FR',
  en_GB = 'en_GB'
}
